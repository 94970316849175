import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';

import * as layoutConstants from '../constants/layout';

import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';
import AdminUserChats from '../pages/apps/Ecommerce/AddProductsAndBuyers/AdminUserChats';
import AdminUserAssociates from '../pages/apps/Ecommerce/AddProductsAndBuyers/AdminUserAssociates';
import AdminAssociates from '../pages/apps/Ecommerce/AddProductsAndBuyers/AdminAssociates';
import AdminAssociatesIncome from '../pages/apps/Ecommerce/AddProductsAndBuyers/AdminAssociatesIncome';
import AdminFinalIncome from '../pages/apps/Ecommerce/AddProductsAndBuyers/AdminFinalIncome';
import Video from '../pages/apps/Ecommerce/Video';
import ProductsActiveGoogle from '../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsActiveGoogle';

const ChatApp = React.lazy(() => import('../pages/apps/Chat'));
const AddProductsAndBuyers = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/index'));
const ProductsTemporaer = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsTemporaer'));
const ProductsActive = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsActive'));
const ProductsPassiv = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsPassiv'));
const ProductsVerkauft = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsVerkauft'));
const ProductsGeliefert = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsGeliefert'));
const ProductsArchiv = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsArchiv'));
const ProductsGeloescht = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsGeloescht'));
const ProductsAlle = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsAlle'));
const ProductsKaeufer = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/ProductsKaeufer'));
const CalendarActive = React.lazy(() => import('../pages/apps/Ecommerce/AddCalenderItems/index'));
const AddProfile = React.lazy(() => import('../pages/apps/Ecommerce/Profile/index'));
const Preise = React.lazy(() => import('../pages/apps/Ecommerce/Pricing'));
const Impressum = React.lazy(() => import('../pages/apps/Ecommerce/FAQ'));
const AppStripe = React.lazy(() => import('../pages/apps/Ecommerce/Stripe/AppStripe'));

const StripeRegister = React.lazy(() => import('../pages/apps/Ecommerce/Stripe/Register'));
const StripeAccount = React.lazy(() => import('../pages/apps/Ecommerce/Stripe/Account'));
const StripeCancel = React.lazy(() => import('../pages/apps/Ecommerce/Stripe/Cancel'));
const StripePrices = React.lazy(() => import('../pages/apps/Ecommerce/Stripe/Prices'));
const StripeSubscribe = React.lazy(() => import('../pages/apps/Ecommerce/Stripe/Subscribe'));
const DeliveryNote = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/DeliveryNote'));

const AdminUser = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/AdminUser'));
const AdminUserRuleBreaks = React.lazy(() =>
    import('../pages/apps/Ecommerce/AddProductsAndBuyers/AdminUserRuleBreaks')
);

const AdminCoupons = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/AdminCoupons'));
const AdminProducts = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/AdminProducts'));
const AdminMediators = React.lazy(() => import('../pages/apps/Ecommerce/AddProductsAndBuyers/AdminMediators'));
const TheLog = React.lazy(() => import('../pages/apps/TheLog'));

const ShopInfos = React.lazy(() => import('../pages/apps/Ecommerce/ShopInfos'));

// - email
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        /* { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        }, */
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: '/',
                    element: <LoadComponent component={AddProductsAndBuyers} />,
                },
                {
                    path: 'chat',
                    element: <LoadComponent component={ChatApp} />,
                },
                {
                    path: 'inbox',
                    element: <LoadComponent component={Inbox} />,
                },
                {
                    path: 'mail-details',
                    element: <LoadComponent component={EmailDetail} />,
                },
                {
                    path: 'the-log',
                    element: <LoadComponent component={TheLog} />,
                },
                {
                    path: 'add-product',
                    element: <LoadComponent component={AddProductsAndBuyers} />,
                },

                {
                    path: 'products-temp',
                    element: <LoadComponent component={ProductsTemporaer} />,
                },
                {
                    path: 'products-active',
                    element: <LoadComponent component={ProductsActive} />,
                },
                {
                    path: 'products-active-seo',
                    element: <LoadComponent component={ProductsActiveGoogle} />,
                },
                {
                    path: 'products-passive',
                    element: <LoadComponent component={ProductsPassiv} />,
                },
                {
                    path: 'products-sold',
                    element: <LoadComponent component={ProductsVerkauft} />,
                },
                {
                    path: 'products-shipped',
                    element: <LoadComponent component={ProductsGeliefert} />,
                },
                {
                    path: 'products-archive',
                    element: <LoadComponent component={ProductsArchiv} />,
                },
                {
                    path: 'products-deleted',
                    element: <LoadComponent component={ProductsGeloescht} />,
                },
                {
                    path: 'products-all',
                    element: <LoadComponent component={ProductsAlle} />,
                },
                {
                    path: 'products-buyers',
                    element: <LoadComponent component={ProductsKaeufer} />,
                },
                {
                    path: 'calendar',
                    element: <LoadComponent component={CalendarActive} />,
                },

                {
                    path: 'profile',
                    element: <LoadComponent component={AddProfile} />,
                },
                {
                    path: 'pricing',
                    element: <LoadComponent component={Preise} />,
                },
                {
                    path: 'video',
                    element: <LoadComponent component={Video} />,
                },
                {
                    path: 'impressum',
                    element: <LoadComponent component={Impressum} />,
                },
                {
                    path: 'stripe',
                    element: <LoadComponent component={AppStripe} />,
                },
                {
                    path: 'stripe-register',
                    element: <LoadComponent component={StripeRegister} />,
                },

                {
                    path: 'stripe-account',
                    element: <LoadComponent component={StripeAccount} />,
                },
                {
                    path: 'stripe-cancel',
                    element: <LoadComponent component={StripeCancel} />,
                },
                {
                    path: 'stripe-prices',
                    element: <LoadComponent component={StripePrices} />,
                },

                {
                    path: 'stripe-subscribe',
                    element: <LoadComponent component={StripeSubscribe} />,
                },

                {
                    path: 'delivery-note',
                    element: <LoadComponent component={DeliveryNote} />,
                },

                {
                    path: 'mediator',
                    element: <LoadComponent component={AdminMediators} />,
                },
                {
                    path: 'associate',
                    element: <LoadComponent component={AdminAssociates} />,
                },
                {
                    path: 'associate-income',
                    element: <LoadComponent component={AdminAssociatesIncome} />,
                },

                {
                    path: 'admin-final-income',
                    element: <LoadComponent component={AdminFinalIncome} />,
                },

                {
                    path: 'penti',
                    element: <LoadComponent component={AdminUser} />,
                },
                {
                    path: 'associates',
                    element: <LoadComponent component={AdminUserAssociates} />,
                },
                {
                    path: 'rule-breaks',
                    element: <LoadComponent component={AdminUserRuleBreaks} />,
                },
                {
                    path: 'rule-breaks-chat',
                    element: <LoadComponent component={AdminUserChats} />,
                },
                {
                    path: 'pentano',
                    element: <LoadComponent component={AdminProducts} />,
                },
                {
                    path: 'coupon',
                    element: <LoadComponent component={AdminCoupons} />,
                },
                {
                    path: 'shop',
                    element: <LoadComponent component={ShopInfos} />,
                },

                {
                    path: '*',
                    element: <LoadComponent component={Preise} />,
                },

                /*  {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'analytics',
                            element: <LoadComponent component={AnalyticsDashboard} />,
                        },
                        {
                            path: 'ecommerce',
                            element: <LoadComponent component={AddProductsAndBuyers} />,
                        },
                        {
                            path: 'project',
                            element: <LoadComponent component={ProjectDashboard} />,
                        },
                        {
                            path: 'e-wallet',
                            element: <LoadComponent component={EWalletDashboard} />,
                        },
                    ],
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'calendargo',
                            element: <LoadComponent component={CalendarApp} />,
                        },
                        {
                            path: 'chat',
                            element: <LoadComponent component={ChatApp} />,
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'dashboard',
                                    element: <LoadComponent component={CRMDashboard} />,
                                },
                                {
                                    path: 'projects',
                                    element: <LoadComponent component={CRMProjects} />,
                                },
                                {
                                    path: 'management',
                                    element: <LoadComponent component={CRMManagement} />,
                                },
                                {
                                    path: 'clients',
                                    element: <LoadComponent component={CRMClients} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={CRMOrderList} />,
                                },
                            ],
                        },
                        {
                            path: 'ecommerce',
                            children: [
                                {
                                    path: 'productsgo',
                                    element: <LoadComponent component={EcommerceProducts} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProductDetails} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={Orders} />,
                                },
                                {
                                    path: 'order/details',
                                    element: <LoadComponent component={OrderDetails} />,
                                },
                                {
                                    path: 'customers',
                                    element: <LoadComponent component={Customers} />,
                                },
                                {
                                    path: 'shopping-cart',
                                    element: <LoadComponent component={Cart} />,
                                },
                                {
                                    path: 'checkout',
                                    element: <LoadComponent component={Checkout} />,
                                },
                                {
                                    path: 'sellers',
                                    element: <LoadComponent component={Sellers} />,
                                },
                            ],
                        },
                        {
                            path: 'email',
                            children: [
                                {
                                    path: 'inbox',
                                    element: <LoadComponent component={Inbox} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={EmailDetail} />,
                                },
                            ],
                        },
                        {
                            path: 'tasks',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={TaskList} />,
                                },
                                {
                                    path: 'kanban',
                                    element: <LoadComponent component={Kanban} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={TaskDetails} />,
                                },
                            ],
                        },

                        {
                            path: 'projects',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={Projects} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProjectDetail} />,
                                },
                                {
                                    path: 'gantt',
                                    element: <LoadComponent component={ProjectGannt} />,
                                },
                                {
                                    path: 'new',
                                    element: <LoadComponent component={ProjectForm} />,
                                },
                            ],
                        },
                        {
                            path: 'social',
                            element: <LoadComponent component={SocialFeed} />,
                        },
                        {
                            path: 'file',
                            element: <LoadComponent component={FileManager} />,
                        },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'starter',
                            element: <LoadComponent component={Starter} />,
                        },
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile} />,
                        },
                        {
                            path: 'profile2',
                            element: <LoadComponent component={Profile2} />,
                        },
                        {
                            path: 'pricing',
                            element: <LoadComponent component={Pricing} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                        {
                            path: 'timeline',
                            element: <LoadComponent component={Timeline} />,
                        },
                        {
                            path: 'invoice',
                            element: <LoadComponent component={Invoice} />,
                        },
                        {
                            path: 'faq',
                            element: <LoadComponent component={FAQ} />,
                        },
                        {
                            path: 'preloader',
                            element: <LoadComponent component={PreLoader} />,
                        },
                    ],
                },
                {
                    path: 'ui',
                    children: [
                        {
                            path: 'base-ui',
                            children: [
                                {
                                    path: 'accordions',
                                    element: <LoadComponent component={Accordions} />,
                                },
                                {
                                    path: 'alerts',
                                    element: <LoadComponent component={Alerts} />,
                                },
                                {
                                    path: 'avatars',
                                    element: <LoadComponent component={Avatars} />,
                                },
                                {
                                    path: 'badges',
                                    element: <LoadComponent component={Badges} />,
                                },
                                {
                                    path: 'breadcrumb',
                                    element: <LoadComponent component={Breadcrumbs} />,
                                },
                                {
                                    path: 'buttons',
                                    element: <LoadComponent component={Buttons} />,
                                },
                                {
                                    path: 'cards',
                                    element: <LoadComponent component={Cards} />,
                                },
                                {
                                    path: 'carousel',
                                    element: <LoadComponent component={Carousels} />,
                                },
                                {
                                    path: 'dropdowns',
                                    element: <LoadComponent component={Dropdowns} />,
                                },
                                {
                                    path: 'embedvideo',
                                    element: <LoadComponent component={EmbedVideo} />,
                                },
                                {
                                    path: 'grid',
                                    element: <LoadComponent component={Grid} />,
                                },
                                {
                                    path: 'listgroups',
                                    element: <LoadComponent component={ListGroups} />,
                                },
                                {
                                    path: 'modals',
                                    element: <LoadComponent component={Modals} />,
                                },
                                {
                                    path: 'notifications',
                                    element: <LoadComponent component={Notifications} />,
                                },
                                {
                                    path: 'offcanvas',
                                    element: <LoadComponent component={Offcanvases} />,
                                },
                                {
                                    path: 'paginations',
                                    element: <LoadComponent component={Paginations} />,
                                },
                                // {
                                //     path: 'placeholders',
                                //     element: <LoadComponent component={Placeholders} />,
                                // },
                                {
                                    path: 'popovers',
                                    element: <LoadComponent component={Popovers} />,
                                },
                                {
                                    path: 'progress',
                                    element: <LoadComponent component={Progress} />,
                                },
                                {
                                    path: 'ribbons',
                                    element: <LoadComponent component={Ribbons} />,
                                },
                                {
                                    path: 'spinners',
                                    element: <LoadComponent component={Spinners} />,
                                },
                                {
                                    path: 'tabs',
                                    element: <LoadComponent component={Tabs} />,
                                },
                                {
                                    path: 'tooltips',
                                    element: <LoadComponent component={Tooltips} />,
                                },
                                {
                                    path: 'typography',
                                    element: <LoadComponent component={Typography} />,
                                },
                            ],
                        },
                        {
                            path: 'widgets',
                            element: <LoadComponent component={Widgets} />,
                        },
                        {
                            path: 'extended',
                            children: [
                                {
                                    path: 'dragdrop',
                                    element: <LoadComponent component={DragDrop} />,
                                },
                                {
                                    path: 'rangesliders',
                                    element: <LoadComponent component={RangeSliders} />,
                                },
                                {
                                    path: 'ratings',
                                    element: <LoadComponent component={Ratings} />,
                                },
                            ],
                        },
                        {
                            path: 'icons',
                            children: [
                                {
                                    path: 'unicons',
                                    element: <LoadComponent component={Unicons} />,
                                },
                                {
                                    path: 'mdi',
                                    element: <LoadComponent component={MDIIcons} />,
                                },
                                {
                                    path: 'dripicons',
                                    element: <LoadComponent component={Dripicons} />,
                                },
                            ],
                        },
                        {
                            path: 'forms',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicForms} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={FormAdvanced} />,
                                },
                                {
                                    path: 'validation',
                                    element: <LoadComponent component={FormValidation} />,
                                },
                                {
                                    path: 'wizard',
                                    element: <LoadComponent component={FormWizard} />,
                                },
                                {
                                    path: 'upload',
                                    element: <LoadComponent component={FileUpload} />,
                                },
                                {
                                    path: 'editors',
                                    element: <LoadComponent component={Editors} />,
                                },
                            ],
                        },
                        {
                            path: 'tables',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicTables} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={AdvancedTables} />,
                                },
                            ],
                        },
                        {
                            path: 'charts',
                            children: [
                                {
                                    path: 'apex',
                                    element: <LoadComponent component={ApexChart} />,
                                },
                                {
                                    path: 'brite',
                                    element: <LoadComponent component={BriteChart} />,
                                },
                                {
                                    path: 'chartjs',
                                    element: <LoadComponent component={ChartJs} />,
                                },
                            ],
                        },
                        {
                            path: 'maps',
                            children: [
                                {
                                    path: 'googlemaps',
                                    element: <LoadComponent component={GoogleMaps} />,
                                },
                                {
                                    path: 'vectormaps',
                                    element: <LoadComponent component={VectorMaps} />,
                                },
                            ],
                        },
                    ],
                }, */
            ],
        },
    ]);
};

export { AllRoutes };
