import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { isAuth, getCookie, getLocalStorage } from './helpers';
import Select from 'react-select';
import { useTheme } from '@table-library/react-table-library/theme';
import { useSort } from '@table-library/react-table-library/sort';
import { CompactTable } from '@table-library/react-table-library/compact';

import { toast, Zoom } from 'react-toastify';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { Link } from 'react-router-dom';
import { Row as BSRow, Col, Card, Button } from 'react-bootstrap';

import './style.css';

const AdminUserAssociates = () => {
    const [searchParams] = useSearchParams();

    const [productsActive, setProductsActive] = useState({ nodes: [] });
    const [search, setSearch] = useState('');
    const [hiddenColumns, setHiddenColumns] = React.useState([]);
    const [ids, setIds] = React.useState([]);

    const [percentage, setPercentage] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [land, setLand] = React.useState('');

    const onChangeValues = (e, value) => {
        if (value === 'land') {
            setLand(e.label);
        } else if (value === 'percentage') {
            setPercentage(e.value);
        } else if (value == 'email') {
            setEmail(e.target.value);
        }
    };

    const removeAssociate = (userId) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/remove-user-associate',
            data: { id: userId },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    setProductsActive({ nodes: response.data.data });
                    toast.success(`${email} wurde erfolgreich als Associate entfernt`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (response.status === 402) {
                    toast.error(response.data.data, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    toast.error('Error: Unbekannter Fehler', {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const addUserToAssociate = (theUserId, addOrRemove) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/add-user-to-associate',
            data: { email, land, percentage },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    setProductsActive({ nodes: response.data.data });
                    toast.success(`${email} wurde erfolgreich als Associate hinzugefügt`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (response.status === 201) {
                    toast.info(`${theUserId} wurde erfolgreich als Vermittler entfernt`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (response.status === 402) {
                    toast.error(response.data.data, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    toast.error('Error: Unbekannter Fehler', {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    let theSize = searchParams.get('size');
    theSize = parseInt(theSize);

    let thePage = searchParams.get('page');
    thePage = parseInt(thePage);

    if (isNaN(theSize)) {
        theSize = 20;
    }

    if (isNaN(thePage)) {
        thePage = 0;
    }

    const toggleColumn = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter((v) => v !== column));
        } else {
            setHiddenColumns(hiddenColumns.concat(column));
        }
    };

    const handleExpand = (item) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const navigate = useNavigate();
    useEffect(() => {
        const adminUserLocalStorage = getLocalStorage('user');

        if (!isAuth()) {
            navigate('/add-product');
        } else if (isAuth() && JSON.parse(adminUserLocalStorage).email == 'schmid.justin@gmail.com') {
            const token = getCookie('token');
            axios({
                method: 'post',
                url: process.env.REACT_APP_API + '/receive-all-assosciates-admin',
                data: {},
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('lets go', response);
                        const helperData = response.data.data;
                        setProductsActive({ nodes: helperData });
                    } else {
                        navigate('/add-product');
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                });
        } else {
            navigate('/add-product');
        }
    }, [navigate]);

    const theme = useTheme({
        HeaderRow: `
          background-color: rgba(44, 144, 221, 0.3);
        `,
        Row: `
          &:nth-of-type(odd) {
            background-color: rgba(44, 144, 221, 0.1);
            cursor: pointer;
          }
    
          &:nth-of-type(even) {
            background-color: rgba(44, 144, 221, 0.2);
            cursor: pointer;
        }
        `,
    });

    const handleSearchTitel = (event) => {
        setSearch(event.target.value);
    };

    let dataSearch = productsActive;

    dataSearch = {
        nodes: dataSearch.nodes.filter(
            (item) =>
                (item.id + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.name + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.email + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.vermittler_email + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.createdAt + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.updatedAt + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.stripeId + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.stripePlanPrice + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.language + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.vorname + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.nachname + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.adresse + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.plz + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.ort + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.festnetz + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.mobiltelefon + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.facebook + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.instagram + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.twitter + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.skype + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.bankverbindung + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.deactivated + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.land + '').toLowerCase().includes(search.toLowerCase())
        ),
    };

    const COLUMNS = [
        {
            label: 'ID',
            renderCell: (item) => (
                <Link to={`/pentano?user=${item.id}`}>
                    {item.id ? <span id={item.id}>{item.id}</span> : <i>nicht definiert</i>}
                </Link>
            ),
            sort: { sortKey: 'ID' },
            hide: hiddenColumns.includes('ID'),
            resize: true,
        },

        {
            label: 'E-Mail',
            renderCell: (item) => (
                <Link to={`/associate?email=${item.email}`}>
                    <span>{item.email ? item.email : <i>nicht definiert</i>}</span>
                </Link>
            ),

            sort: { sortKey: 'EMAIL' },
            hide: hiddenColumns.includes('EMAIL'),
            resize: true,
        },

        {
            label: 'Erstelldatum',
            renderCell: (item) => <span>{item.createdAt ? item.createdAt : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'STARTDATUM' },
            hide: hiddenColumns.includes('STARTDATUM'),
            resize: true,
        },
        {
            label: 'Land',
            renderCell: (item) => <span>{item.land ? item.land : <i>nicht definiert</i>}</span>,
            /*   renderCell: (item) => {
                item.vermittler_email ? (
                    <Link to={`/mediator?email=${item.vermittler_email}`}>{item.vermittler_email}</Link>
                ) : (
                    <i>nicht definiert</i>
                );
            }, */
            sort: { sortKey: 'LAND' },
            hide: hiddenColumns.includes('LAND'),
            resize: true,
        },
        {
            label: 'Prozent',
            renderCell: (item) => <span>{item.percentage ? item.percentage + '%' : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'PROZENT' },
            hide: hiddenColumns.includes('STRIPEDID'),
            resize: true,
        },

        {
            label: 'Associate entfernen',
            renderCell: (item) => (
                <Button onClick={(event) => removeAssociate(item.id)} variant="danger">
                    Associate entfernen
                </Button>
            ),
            sort: { sortKey: 'ASSOCIATE' },
            hide: hiddenColumns.includes('ASSOCIATE'),
            resize: true,
        },
    ];

    const sort = useSort(
        dataSearch,
        {
            onChange: onSortChange,
        },
        {
            sortFns: {
                ID: (array) => array.sort((a, b) => a.id - b.id),
                NAME: (array) => array.sort((a, b) => a.name - b.name),
                EMAIL: (array) => array.sort((a, b) => a.email - b.email),
                STARTDATUM: (array) => array.sort((a, b) => a.createdAt - b.createdAt),
                LAND: (array) => array.sort((a, b) => a.vermittler - b.vermittler),
                PROZENT: (array) => array.sort((a, b) => a.stripeId - b.stripeId),
                AMOUNT: (array) => array.sort((a, b) => a.stripePlanPrice - b.stripePlanPrice),
                ASSOCIATE: (array) => array.sort((a, b) => a.mediator - b.mediator),
            },
        }
    );

    function onSortChange(action, state) {
        /* console.log(action, state); */
    }

    const ROW_PROPS = {
        onClick: handleExpand,
    };

    const percentageOneToAHundred = [];
    for (let i = 1; i < 101; i++) {
        percentageOneToAHundred.push({ value: i, label: i + '%' });
    }

    const ROW_OPTIONS = {
        renderAfterRow: (item) => (
            <>
                {ids.includes(item.id) && (
                    <>
                        <BSRow
                            style={{
                                display: 'flex',
                                gridColumn: '1 / -1',
                                backgroundColor: 'rgba(44, 144, 221, 0.3)',
                                margin: '0px',
                                borderTop:
                                    item.user.isOnline && item.user.isOnline == '1'
                                        ? '2px solid green'
                                        : '2px solid red',
                                borderLeft:
                                    item.user.isOnline && item.user.isOnline == '1'
                                        ? '2px solid green'
                                        : '2px solid red',
                                borderRight:
                                    item.user.isOnline && item.user.isOnline == '1'
                                        ? '2px solid green'
                                        : '2px solid red',
                            }}>
                            <h3 style={{ marginBottom: '0', paddingBottom: '0', textDecoration: 'underline' }}>
                                User Infos on Registration
                            </h3>
                            <Col style={{ padding: '20px' }}>
                                <strong>ID: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.id ? item.user.id : <i>nicht definiert</i>}
                                </span>

                                <br />
                                <strong>Name: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.name ? item.user.name : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>E-Mail: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.email ? item.user.email : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Shopname: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.shopName ? (
                                        <a
                                            href={`${process.env.REACT_APP_HTTPS}://${item.user.shopName}.${process.env.REACT_APP_SMS}`}
                                            target="_blank"
                                            rel="noreferrer">
                                            {item.user.shopName}
                                        </a>
                                    ) : (
                                        <i>nicht definiert</i>
                                    )}
                                </span>
                                <br />
                                <strong>Language: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.language ? item.user.language : <i>nicht definiert</i>}
                                </span>
                                <br />
                            </Col>

                            <Col style={{ padding: '20px' }}>
                                <strong>Adresse: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.adresse ? item.user.adresse : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>PLZ: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.plz ? item.user.plz : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Ort: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.ort ? item.user.ort : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Land: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.land ? item.user.land : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Online </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.isOnline && item.user.isOnline == '1' ? (
                                        'Ja'
                                    ) : item.user.isOnline == '0' ? (
                                        'Nein'
                                    ) : (
                                        <i>nicht definiert</i>
                                    )}
                                </span>
                                <br />
                            </Col>
                            <Col style={{ padding: '20px' }}>
                                <strong>Stripe-ID: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.stripeId ? item.user.stripeId : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Stripe-Plan: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.stripePlan ? item.user.stripePlan : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Stripe-Plan-Preis: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.stripePlanPrice ? item.user.stripePlanPrice : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Erstellt am: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.createdAt ? item.user.createdAt : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Updated am: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.updatedAt ? item.user.updatedAt : <i>nicht definiert</i>}
                                </span>
                                <br />
                            </Col>
                            <Col style={{ padding: '20px' }}>
                                <strong>Vermittler: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.vermittler ? item.user.vermittler : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Mediator: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.mediator && item.user.mediator == '1' ? (
                                        'Ja'
                                    ) : item.user.mediator == '0' ? (
                                        'Nein'
                                    ) : (
                                        <i>nicht definiert</i>
                                    )}
                                </span>
                                <br />
                                <strong>Shop Status (activated/deactivated): </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.deactivated ? item.user.deactivated : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Chat Status (activated/deactivated): </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.chat_deactivated ? item.user.chat_deactivated : <i>nicht definiert</i>}
                                </span>
                                <br />
                                <strong>Site Location: </strong>
                                <span className="showTableZFAdmin">
                                    {item.user.siteLocation ? item.user.siteLocation : <i>nicht definiert</i>}
                                </span>
                            </Col>
                        </BSRow>
                    </>
                )}
            </>
        ),
    };

    return (
        <>
            {/*  <PageTitle
                breadCrumbItems={[{ label: 'Was sind aktive Produkte?', path: '/apps/ecommerce/products' }]}
                title={'Aktive Produkte'}
            /> */}

            <BSRow className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <BSRow>
                                <Col sm={12} className="mb-3">
                                    <div style={{}}>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="ID">
                                                <input
                                                    id="ID"
                                                    type="checkbox"
                                                    value="ID"
                                                    checked={!hiddenColumns.includes('ID')}
                                                    onChange={() => toggleColumn('ID')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                ID
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="EMAIL">
                                                <input
                                                    id="EMAIL"
                                                    type="checkbox"
                                                    value="EMAIL"
                                                    checked={!hiddenColumns.includes('EMAIL')}
                                                    onChange={() => toggleColumn('EMAIL')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                E-Mail
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="STARTDATUM">
                                                <input
                                                    id="STARTDATUM"
                                                    type="checkbox"
                                                    value="STARTDATUM"
                                                    checked={!hiddenColumns.includes('STARTDATUM')}
                                                    onChange={() => toggleColumn('STARTDATUM')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Erstelldatum
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="LAND">
                                                <input
                                                    id="LAND"
                                                    type="checkbox"
                                                    value="LAND"
                                                    checked={!hiddenColumns.includes('LAND')}
                                                    onChange={() => toggleColumn('LAND')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Land
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="PROZENT">
                                                <input
                                                    id="PROZENT"
                                                    type="checkbox"
                                                    value="PROZENT"
                                                    checked={!hiddenColumns.includes('PROZENT')}
                                                    onChange={() => toggleColumn('PROZENT')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Prozent
                                            </label>
                                        </div>

                                        <div style={{ float: 'right' }}>
                                            <Link to={'/penti'}>
                                                <Button>Alle Benutzer</Button>
                                            </Link>
                                            <Link to={'/coupon'}>
                                                <Button className="ms-2">Coupons</Button>
                                            </Link>
                                            <Link to={'/rule-breaks'}>
                                                <Button className="ms-2">Rule Breaks</Button>
                                            </Link>
                                            <Link to={'/admin-final-income'}>
                                                <Button className="ms-2">Final Income</Button>
                                            </Link>
                                        </div>
                                    </div>
                                    <br />
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">
                                            <strong>Suche...</strong>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="ID, Name, E-Mail, Erstelldatum, Updatedatum, Stripe ID, Betrag, Firma, Vorname, Nachname, Adresse, PLZ, Ort, Festnetz, Mobiltelefon, Facebook, Instagram, Twitter, Skype "
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={
                                                searchParams.get('search-user')
                                                    ? searchParams.get('search-user')
                                                    : search
                                            }
                                            onChange={handleSearchTitel}
                                        />
                                    </InputGroup>
                                    <br />
                                    <BSRow>
                                        <Col>
                                            <Form.Control
                                                className="me-4"
                                                placeholder="E-Mail"
                                                value={email}
                                                onChange={(e) => onChangeValues(e, 'email')}
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                className="react-select landUniIconContainerPublicProfile"
                                                classNamePrefix="react-select"
                                                placeholder="Percentage"
                                                onChange={(e) => {
                                                    onChangeValues(e, 'percentage');
                                                }}
                                                options={percentageOneToAHundred}></Select>
                                        </Col>
                                        <Col>
                                            <Select
                                                className="react-select landUniIconContainerPublicProfile"
                                                classNamePrefix="react-select"
                                                onChange={(e) => {
                                                    onChangeValues(e, 'land');
                                                }}
                                                placeholder="Land"
                                                options={[
                                                    { value: 'afghanistan', label: 'Afghanistan' },
                                                    { value: 'aland_islands', label: 'Aland Islands' },
                                                    { value: 'albania', label: 'Albania' },
                                                    { value: 'algeria', label: 'Algeria' },
                                                    { value: 'american_samoa', label: 'American Samoa' },
                                                    { value: 'andorra', label: 'Andorra' },
                                                    { value: 'angola', label: 'Angola' },
                                                    { value: 'anguilla', label: 'Anguilla' },
                                                    { value: 'antarctica', label: 'Antarctica' },
                                                    { value: 'antigua_and_barbuda', label: 'Antigua and Barbuda' },
                                                    { value: 'argentina', label: 'Argentina' },
                                                    { value: 'armenia', label: 'Armenia' },
                                                    { value: 'aruba', label: 'Aruba' },
                                                    { value: 'australia', label: 'Australia' },
                                                    { value: 'austria', label: 'Austria' },
                                                    { value: 'azerbaijan', label: 'Azerbaijan' },
                                                    { value: 'bahamas', label: 'Bahamas' },
                                                    { value: 'bahrain', label: 'Bahrain' },
                                                    { value: 'bangladesh', label: 'Bangladesh' },
                                                    { value: 'barbados', label: 'Barbados' },
                                                    { value: 'belarus', label: 'Belarus' },
                                                    { value: 'belgium', label: 'Belgium' },
                                                    { value: 'belize', label: 'Belize' },
                                                    { value: 'benin', label: 'Benin' },
                                                    { value: 'bermuda', label: 'Bermuda' },
                                                    { value: 'bhutan', label: 'Bhutan' },
                                                    { value: 'bolivia', label: 'Bolivia' },
                                                    { value: 'bonaire', label: 'Bonaire' },
                                                    {
                                                        value: 'bosnia_and_herzegovina',
                                                        label: 'Bosnia and Herzegovina',
                                                    },
                                                    { value: 'botswana', label: 'Botswana' },
                                                    { value: 'bouvet_island', label: 'Bouvet Island' },
                                                    { value: 'brazil', label: 'Brazil' },
                                                    { value: 'brunei_darussalam', label: 'Brunei Darussalam' },
                                                    { value: 'bulgaria', label: 'Bulgaria' },
                                                    { value: 'burkina_faso', label: 'Burkina Faso' },
                                                    { value: 'burundi', label: 'Burundi' },
                                                    { value: 'cabo_verde', label: 'Cabo Verde' },
                                                    { value: 'cambodia', label: 'Cambodia' },
                                                    { value: 'cameroon', label: 'Cameroon' },
                                                    { value: 'canada', label: 'Canada' },
                                                    { value: 'cayman_islands', label: 'Cayman Islands' },
                                                    {
                                                        value: 'central_african_republic',
                                                        label: 'Central African Republic',
                                                    },
                                                    { value: 'chad', label: 'Chad' },
                                                    { value: 'chile', label: 'Chile' },
                                                    { value: 'china', label: 'China' },
                                                    { value: 'christmas_island', label: 'Christmas Island' },
                                                    { value: 'cocos_islands', label: 'Cocos Islands' },
                                                    { value: 'colombia', label: 'Colombia' },
                                                    { value: 'comoros', label: 'Comoros' },
                                                    { value: 'congo', label: 'Congo' },
                                                    { value: 'congo', label: 'Congo' },
                                                    { value: 'cook_islands', label: 'Cook Islands' },
                                                    { value: 'costa_rica', label: 'Costa Rica' },
                                                    { value: "cote_d'ivoire", label: "Cote d'Ivoire" },
                                                    { value: 'croatia', label: 'Croatia' },
                                                    { value: 'cuba', label: 'Cuba' },
                                                    { value: 'curacao', label: 'Curacao' },
                                                    { value: 'cyprus', label: 'Cyprus' },
                                                    { value: 'czech_republic', label: 'Czech Republic' },
                                                    { value: 'denmark', label: 'Denmark' },
                                                    { value: 'djibouti', label: 'Djibouti' },
                                                    { value: 'dominica', label: 'Dominica' },
                                                    { value: 'dominican_republic', label: 'Dominican Republic' },
                                                    { value: 'ecuador', label: 'Ecuador' },
                                                    { value: 'egypt', label: 'Egypt' },
                                                    { value: 'el_salvador', label: 'El Salvador' },
                                                    { value: 'equatorial_guinea', label: 'Equatorial Guinea' },
                                                    { value: 'eritrea', label: 'Eritrea' },
                                                    { value: 'estonia', label: 'Estonia' },
                                                    { value: 'ethiopia', label: 'Ethiopia' },
                                                    { value: 'falkland_islands', label: 'Falkland Islands' },
                                                    { value: 'faroe_islands', label: 'Faroe Islands' },
                                                    { value: 'fiji', label: 'Fiji' },
                                                    { value: 'finland', label: 'Finland' },
                                                    { value: 'france', label: 'France' },
                                                    { value: 'french_guiana', label: 'French Guiana' },
                                                    { value: 'french_polynesia', label: 'French Polynesia' },
                                                    {
                                                        value: 'french_southern_territories',
                                                        label: 'French Southern Territories',
                                                    },
                                                    { value: 'gabon', label: 'Gabon' },
                                                    { value: 'gambia', label: 'Gambia' },
                                                    { value: 'georgia', label: 'Georgia' },
                                                    { value: 'germany', label: 'Germany' },
                                                    { value: 'ghana', label: 'Ghana' },
                                                    { value: 'gibraltar', label: 'Gibraltar' },
                                                    { value: 'greece', label: 'Greece' },
                                                    { value: 'greenland', label: 'Greenland' },
                                                    { value: 'grenada', label: 'Grenada' },
                                                    { value: 'guadeloupe', label: 'Guadeloupe' },
                                                    { value: 'guam', label: 'Guam' },
                                                    { value: 'guatemala', label: 'Guatemala' },
                                                    { value: 'guernsey', label: 'Guernsey' },
                                                    { value: 'guinea', label: 'Guinea' },
                                                    { value: 'guinea-bissau', label: 'Guinea-Bissau' },
                                                    { value: 'guyana', label: 'Guyana' },
                                                    { value: 'haiti', label: 'Haiti' },
                                                    {
                                                        value: 'heard_and_mcdonald_islands',
                                                        label: 'Heard and McDonald Islands',
                                                    },
                                                    { value: 'holy_see', label: 'Holy See' },
                                                    { value: 'honduras', label: 'Honduras' },
                                                    { value: 'hong_kong', label: 'Hong Kong' },
                                                    { value: 'hungary', label: 'Hungary' },
                                                    { value: 'iceland', label: 'Iceland' },
                                                    { value: 'india', label: 'India' },
                                                    { value: 'indonesia', label: 'Indonesia' },
                                                    { value: 'iran', label: 'Iran' },
                                                    { value: 'iraq', label: 'Iraq' },
                                                    { value: 'ireland', label: 'Ireland' },
                                                    { value: 'isle_of_man', label: 'Isle of Man' },
                                                    { value: 'israel', label: 'Israel' },
                                                    { value: 'italy', label: 'Italy' },
                                                    { value: 'jamaica', label: 'Jamaica' },
                                                    { value: 'japan', label: 'Japan' },
                                                    { value: 'jersey', label: 'Jersey' },
                                                    { value: 'jordan', label: 'Jordan' },
                                                    { value: 'kazakhstan', label: 'Kazakhstan' },
                                                    { value: 'kenya', label: 'Kenya' },
                                                    { value: 'kiribati', label: 'Kiribati' },
                                                    { value: 'korea', label: 'Korea' },
                                                    { value: 'kosovo', label: 'Kosovo' },
                                                    { value: 'kuwait', label: 'Kuwait' },
                                                    { value: 'kyrgyzstan', label: 'Kyrgyzstan' },
                                                    { value: 'lao_peoples', label: "Lao People's" },
                                                    { value: 'latvia', label: 'Latvia' },
                                                    { value: 'lebanon', label: 'Lebanon' },
                                                    { value: 'lesotho', label: 'Lesotho' },
                                                    { value: 'liberia', label: 'Liberia' },
                                                    { value: 'libya', label: 'Libya' },
                                                    { value: 'liechtenstein', label: 'Liechtenstein' },
                                                    { value: 'lithuania', label: 'Lithuania' },
                                                    { value: 'luxembourg', label: 'Luxembourg' },
                                                    { value: 'macao', label: 'Macao' },
                                                    { value: 'macedonia', label: 'Macedonia' },
                                                    { value: 'madagascar', label: 'Madagascar' },
                                                    { value: 'malawi', label: 'Malawi' },
                                                    { value: 'malaysia', label: 'Malaysia' },
                                                    { value: 'maldives', label: 'Maldives' },
                                                    { value: 'mali', label: 'Mali' },
                                                    { value: 'malta', label: 'Malta' },
                                                    { value: 'marshall_islands', label: 'Marshall Islands' },
                                                    { value: 'martinique', label: 'Martinique' },
                                                    { value: 'mauritania', label: 'Mauritania' },
                                                    { value: 'mauritius', label: 'Mauritius' },
                                                    { value: 'mayotte', label: 'Mayotte' },
                                                    { value: 'mexico', label: 'Mexico' },
                                                    { value: 'micronesia', label: 'Micronesia' },
                                                    { value: 'moldova', label: 'Moldova' },
                                                    { value: 'monaco', label: 'Monaco' },
                                                    { value: 'mongolia', label: 'Mongolia' },
                                                    { value: 'montenegro', label: 'Montenegro' },
                                                    { value: 'montserrat', label: 'Montserrat' },
                                                    { value: 'morocco', label: 'Morocco' },
                                                    { value: 'mozambique', label: 'Mozambique' },
                                                    { value: 'myanmar', label: 'Myanmar' },
                                                    { value: 'namibia', label: 'Namibia' },
                                                    { value: 'nauru', label: 'Nauru' },
                                                    { value: 'nepal', label: 'Nepal' },
                                                    { value: 'netherlands', label: 'Netherlands' },
                                                    { value: 'new_caledonia', label: 'New Caledonia' },
                                                    { value: 'new_zealand', label: 'New Zealand' },
                                                    { value: 'nicaragua', label: 'Nicaragua' },
                                                    { value: 'niger', label: 'Niger' },
                                                    { value: 'nigeria', label: 'Nigeria' },
                                                    { value: 'niue', label: 'Niue' },
                                                    { value: 'norfolk_island', label: 'Norfolk Island' },
                                                    {
                                                        value: 'northern_mariana_islands',
                                                        label: 'Northern Mariana Islands',
                                                    },
                                                    { value: 'norway', label: 'Norway' },
                                                    { value: 'oman', label: 'Oman' },
                                                    { value: 'pakistan', label: 'Pakistan' },
                                                    { value: 'palau', label: 'Palau' },
                                                    { value: 'palestine', label: 'Palestine' },
                                                    { value: 'panama', label: 'Panama' },
                                                    { value: 'papua_new_guinea', label: 'Papua New Guinea' },
                                                    { value: 'paraguay', label: 'Paraguay' },
                                                    { value: 'peru', label: 'Peru' },
                                                    { value: 'philippines', label: 'Philippines' },
                                                    { value: 'pitcairn', label: 'Pitcairn' },
                                                    { value: 'poland', label: 'Poland' },
                                                    { value: 'portugal', label: 'Portugal' },
                                                    { value: 'puerto_rico', label: 'Puerto Rico' },
                                                    { value: 'qatar', label: 'Qatar' },
                                                    { value: 'reunion', label: 'Reunion' },
                                                    { value: 'romania', label: 'Romania' },
                                                    { value: 'russian_federation', label: 'Russian Federation' },
                                                    { value: 'rwanda', label: 'Rwanda' },
                                                    { value: 'saint_barthelemy', label: 'Saint Barthelemy' },
                                                    { value: 'saint_helena', label: 'Saint Helena' },
                                                    { value: 'saint_kitts_and_nevis', label: 'Saint Kitts and Nevis' },
                                                    { value: 'saint_lucia', label: 'Saint Lucia' },
                                                    { value: 'saint_martin', label: 'Saint Martin' },
                                                    {
                                                        value: 'saint_pierre_and_miquelon',
                                                        label: 'Saint Pierre and Miquelon',
                                                    },
                                                    {
                                                        value: 'saint_vincent_and_the_grenadines',
                                                        label: 'Saint Vincent and the Grenadines',
                                                    },
                                                    { value: 'samoa', label: 'Samoa' },
                                                    { value: 'san_marino', label: 'San Marino' },
                                                    { value: 'sao_tome_and_principe', label: 'Sao Tome and Principe' },
                                                    { value: 'saudi_arabia', label: 'Saudi Arabia' },
                                                    { value: 'senegal', label: 'Senegal' },
                                                    { value: 'serbia', label: 'Serbia' },
                                                    { value: 'seychelles', label: 'Seychelles' },
                                                    { value: 'sierra_leone', label: 'Sierra Leone' },
                                                    { value: 'singapore', label: 'Singapore' },
                                                    { value: 'sint_maarten', label: 'Sint Maarten' },
                                                    { value: 'slovakia', label: 'Slovakia' },
                                                    { value: 'slovenia', label: 'Slovenia' },
                                                    { value: 'solomon_islands', label: 'Solomon Islands' },
                                                    { value: 'somalia', label: 'Somalia' },
                                                    { value: 'south_africa', label: 'South Africa' },
                                                    { value: 'south_georgia', label: 'South Georgia' },
                                                    { value: 'south_sudan', label: 'South Sudan' },
                                                    { value: 'spain', label: 'Spain' },
                                                    { value: 'sri_lanka', label: 'Sri Lanka' },
                                                    { value: 'sudan', label: 'Sudan' },
                                                    { value: 'suriname', label: 'Suriname' },
                                                    {
                                                        value: 'svalbard_and_jan mayen',
                                                        label: 'Svalbard and Jan Mayen',
                                                    },
                                                    { value: 'swaziland', label: 'Swaziland' },
                                                    { value: 'sweden', label: 'Sweden' },
                                                    { value: 'switzerland', label: 'Switzerland' },
                                                    { value: 'syrian_arab_republic', label: 'Syrian Arab Republic' },
                                                    { value: 'taiwan', label: 'Taiwan' },
                                                    { value: 'tajikistan', label: 'Tajikistan' },
                                                    { value: 'tanzania', label: 'Tanzania' },
                                                    { value: 'thailand', label: 'Thailand' },
                                                    { value: 'timor_leste', label: 'Timor Leste' },
                                                    { value: 'togo', label: 'Togo' },
                                                    { value: 'tokelau', label: 'Tokelau' },
                                                    { value: 'tonga', label: 'Tonga' },
                                                    { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
                                                    { value: 'tunisia', label: 'Tunisia' },
                                                    { value: 'turkey', label: 'Turkey' },
                                                    { value: 'turkmenistan', label: 'Turkmenistan' },
                                                    {
                                                        value: 'turks_and_caicos islands',
                                                        label: 'Turks and Caicos Islands',
                                                    },
                                                    { value: 'tuvalu', label: 'Tuvalu' },
                                                    { value: 'uganda', label: 'Uganda' },
                                                    { value: 'ukraine', label: 'Ukraine' },
                                                    { value: 'united_arab_emirates', label: 'United Arab Emirates' },
                                                    { value: 'united_kingdom', label: 'United Kingdom' },
                                                    {
                                                        value: 'united_states_of america',
                                                        label: 'United States of America (USA)',
                                                    },
                                                    { value: 'uruguay', label: 'Uruguay' },
                                                    { value: 'uzbekistan', label: 'Uzbekistan' },
                                                    { value: 'vanuatu', label: 'Vanuatu' },
                                                    { value: 'venezuela', label: 'Venezuela' },
                                                    { value: 'viet_nam', label: 'Viet Nam' },
                                                    { value: 'virgin_islands', label: 'Virgin Islands' },
                                                    { value: 'virgin_islands', label: 'Virgin Islands' },
                                                    { value: 'wallis_and_futuna', label: 'Wallis and Futuna' },
                                                    { value: 'western_sahara', label: 'Western Sahara' },
                                                    { value: 'yemen', label: 'Yemen' },
                                                    { value: 'zambia', label: 'Zambia' },
                                                    { value: 'zimbabwe', label: 'Zimbabwe' },
                                                ]}></Select>
                                        </Col>
                                        <Col>
                                            <Button onClick={addUserToAssociate} variant="primary">
                                                Associate erstellen
                                            </Button>
                                        </Col>
                                    </BSRow>
                                </Col>

                                <Col sm={3}>
                                    {/* <div className="text-sm-end">
                                        <Button variant="primary" className="mb-2 me-1">
                                            <i className="mdi mdi-cog-outline"></i>
                                        </Button>

                                        <Button variant="light" className="mb-2 me-1">
                                            Import
                                        </Button>

                                        <Button variant="light" className="mb-2">
                                            Export
                                        </Button>
                                    </div> */}
                                </Col>
                            </BSRow>

                            <CompactTable
                                columns={COLUMNS}
                                data={dataSearch}
                                theme={theme}
                                rowProps={ROW_PROPS}
                                rowOptions={ROW_OPTIONS}
                                sort={sort}
                                layout={{ fixedHeader: true }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </BSRow>
        </>
    );
};

export default AdminUserAssociates;
