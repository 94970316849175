// @flow
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// actions
import {
    changeLayout,
    changeLayoutColor,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
} from '../../redux/actions';

import * as layoutConstants from '../../constants/layout';
import LayoutTypes from './LayoutTypes';
import LayoutColor from './LayoutColor';
import LayoutMyConf from './LayoutMyConf';
import LeftSideBarTheme from './LeftSideBarTheme';
import LeftSideBarType from './LeftSideBarType';
import { getCookie, setCookie } from '../../context/helpers';
import AuthContext from '../../context/auth-context';

const ThemeCustomizer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { layoutColor, layoutType, layoutWidth, leftSideBarType, leftSideBarTheme } = useSelector((state) => ({
        layoutColor: state.Layout.layoutColor,
        layoutType: state.Layout.layoutType,
        layoutWidth: state.Layout.layoutWidth,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftSideBarType: state.Layout.leftSideBarType,
    }));

    const [, setDisableLayoutWidth] = useState(false);
    const [disableSidebarTheme, setDisableSidebarTheme] = useState(false);
    const [disableSidebarType, setDisableSidebarType] = useState(false);
    const ctx = useContext(AuthContext);
    /**
     * change state based on props changes
     */
    const _loadStateFromProps = useCallback(() => {
        setDisableLayoutWidth(
            layoutType !== layoutConstants.LAYOUT_DETACHED && layoutType !== layoutConstants.LAYOUT_FULL
        );

        setDisableSidebarTheme(
            layoutType !== layoutConstants.LAYOUT_HORIZONTAL && layoutType !== layoutConstants.LAYOUT_DETACHED
        );
        setDisableSidebarType(layoutType !== layoutConstants.LAYOUT_HORIZONTAL);
    }, [layoutType]);

    useEffect(() => {
        _loadStateFromProps();
    }, [_loadStateFromProps]);

    useEffect(() => {
        if (getCookie('layout_color') === 'dark') {
            dispatch(changeLayoutColor(layoutConstants.LAYOUT_COLOR_DARK));
            ctx.onThemeIsDark(true);
        } else if (getCookie('layout_color') !== 'dark') {
            dispatch(changeLayoutColor(layoutConstants.LAYOUT_COLOR_LIGHT));
            ctx.onThemeIsDark(false);
        } else {
            dispatch(changeLayoutColor(layoutConstants.LAYOUT_COLOR_LIGHT));
        }

        if (getCookie('layout_type') === 'topnav') {
            dispatch(changeLayout(layoutConstants.LAYOUT_HORIZONTAL));
        } else if (getCookie('layout_type') === 'detached') {
            dispatch(changeLayout(layoutConstants.LAYOUT_DETACHED));
        } else if (getCookie('layout_type') === 'full') {
            dispatch(changeLayout(layoutConstants.LAYOUT_FULL));
        } else if (getCookie('layout_type') === 'vertical') {
            dispatch(changeLayout(layoutConstants.LAYOUT_VERTICAL));
        }

        if (getCookie('layout_width') === 'boxed') {
            dispatch(changeLayoutWidth(layoutConstants.LAYOUT_WIDTH_BOXED));
        } else if (getCookie('layout_width') === 'fluid') {
            dispatch(changeLayoutWidth(layoutConstants.LAYOUT_WIDTH_FLUID));
        }

        if (getCookie('left_sidebar') === 'default') {
            dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT));
        } else if (getCookie('left_sidebar') === 'light') {
            dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_LIGHT));
        } else if (getCookie('left_sidebar') === 'dark') {
            dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK));
        }

        if (getCookie('sidebar_type') === 'condensed') {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
        } else if (getCookie('sidebar_type') === 'scrollable') {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE));
        } else if (getCookie('sidebar_type') === 'other') {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED));
        }
    }, []);
    /**
     * On layout change
     */
    const changeLayoutType = (value: any) => {
        var layout = value;
        switch (layout) {
            case 'topnav':
                dispatch(changeLayout(layoutConstants.LAYOUT_HORIZONTAL));
                setCookie('layout_type', 'topnav');
                break;
            case 'detached':
                dispatch(changeLayout(layoutConstants.LAYOUT_DETACHED));
                setCookie('layout_type', 'detached');
                break;
            case 'full':
                dispatch(changeLayout(layoutConstants.LAYOUT_FULL));
                setCookie('layout_type', 'full');
                break;
            default:
                dispatch(changeLayout(layoutConstants.LAYOUT_VERTICAL));
                setCookie('layout_type', 'vertical');
                break;
        }
    };

    /**
     * Change the layout color
     */
    const changeLayoutColorScheme = (value: any) => {
        var mode = value;
        switch (mode) {
            case 'dark':
                dispatch(changeLayoutColor(layoutConstants.LAYOUT_COLOR_DARK));
                setCookie('layout_color', 'dark');
                ctx.onThemeIsDark(true);

                break;
            default:
                dispatch(changeLayoutColor(layoutConstants.LAYOUT_COLOR_LIGHT));
                setCookie('layout_color', 'light');
                ctx.onThemeIsDark(false);
                break;
        }
    };

    /**
     * Change the width mode
     */
    const changeWidthMode = (value: any) => {
        var mode = value;

        switch (mode) {
            case 'boxed':
                dispatch(changeLayoutWidth(layoutConstants.LAYOUT_WIDTH_BOXED));
                setCookie('layout_width', 'boxed');
                break;
            default:
                dispatch(changeLayoutWidth(layoutConstants.LAYOUT_WIDTH_FLUID));
                setCookie('layout_width', 'fluid');
                break;
        }
    };

    /**
     * Changes the theme
     */
    const changeLeftSidebarTheme = (value: any) => {
        var theme = value;
        switch (theme) {
            case 'default':
                dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT));
                setCookie('left_sidebar', 'default');
                break;
            case 'light':
                dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_LIGHT));
                setCookie('left_sidebar', 'light');
                break;
            default:
                dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK));
                setCookie('left_sidebar', 'dark');
                break;
        }
    };

    /**
     * Change the leftsiderbar type
     */
    const changeLeftSiderbarType = (value: any) => {
        var type = value;
        switch (type) {
            case 'condensed':
                dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
                setCookie('sidebar_type', 'condensed');

                break;
            case 'scrollable':
                dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE));
                setCookie('sidebar_type', 'scrollable');
                break;
            default:
                dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED));
                setCookie('sidebar_type', 'other');
                break;
        }
    };

    /**
     * Reset everything
     */
    const reset = () => {
        changeLayoutType(layoutConstants.LAYOUT_VERTICAL);
        changeLayoutColorScheme(layoutConstants.LAYOUT_COLOR_LIGHT);
        changeWidthMode(layoutConstants.LAYOUT_WIDTH_FLUID);
        changeLeftSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK);
        changeLeftSiderbarType(layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE);
    };

    return (
        <React.Fragment>
            <div className="p-3">
                {/*  <div className="alert alert-warning" role="alert">
                    <strong>Customize </strong> the overall color scheme, sidebar menu, etc.
                </div> */}

                {/* LayoutMyConf */}
                <LayoutMyConf />

                {/* Layouts */}
                <LayoutTypes
                    changeLayoutType={changeLayoutType}
                    layoutType={layoutType}
                    layoutConstants={layoutConstants}
                />

                {/* color scheme */}
                <LayoutColor
                    changeLayoutColorScheme={changeLayoutColorScheme}
                    layoutColor={layoutColor}
                    layoutConstants={layoutConstants}
                />

                {/* Width */}
                {/* {disableLayoutWidth && (
                    <LayoutWidth
                        changeWidthMode={changeWidthMode}
                        layoutWidth={layoutWidth}
                        layoutConstants={layoutConstants}
                    />
                )} */}

                {/* Left Sidebar */}
                {disableSidebarTheme && (
                    <LeftSideBarTheme
                        changeLeftSidebarTheme={changeLeftSidebarTheme}
                        leftSideBarTheme={leftSideBarTheme}
                        layoutConstants={layoutConstants}
                    />
                )}

                {/* Left Sidebar Size */}
                {disableSidebarType && (
                    <LeftSideBarType
                        changeLeftSiderbarType={changeLeftSiderbarType}
                        leftSideBarType={leftSideBarType}
                        layoutConstants={layoutConstants}
                    />
                )}

                <div className="d-grid mt-4">
                    <button className="btn btn-primary" id="resetBtn" onClick={() => reset()}>
                        {t('Sidebar.Zuruecksetzen')}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ThemeCustomizer;
