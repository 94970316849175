import React from 'react';
import video from '../../../assets/video/intro_de.mp4';

import { isAuth, getCookie } from './AddProductsAndBuyers/helpers';

const Video = () => {
    return (
        <div className="text-center">
            <video width="1200" height="750" controls>
                <source src={video} type="video/mp4" />
            </video>
        </div>
    );
};

export default Video;
