import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { isAuth, getCookie, setCookie } from '../authTutFiles/helpers';

const AuthContext = React.createContext({
    isLoggedIn: false,
    profileEmail: '',
    profileVorname: '',
    profileNachname: '',
    profileFirma: '',
    imageUrl: '',
    onChangeLogin: (trueOrFalse) => {},
    onChangeVorname: (vorname) => {},
    onChangeNachname: (nachname) => {},
    onChangeFirma: (firma) => {},
    onChangeEmail: (email) => {},
    onChangeImage: (imageUrl) => {},
});

export const AuthContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(isAuth() ? true : false);
    const [profileVorname, setProfileVorname] = useState('');
    const [profileNachname, setProfileNachname] = useState('');
    const [profileFirma, setProfileFirma] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [userIsOnline, setUserIsOnline] = useState(false);
    const [themeDarkBool, setThemeDarkBool] = useState(false);
    const [howItWorks, setHowItWorks] = useState(false);
    const [pricingCurrency, setPricingCurrency] = useState('EUR');
    const [isUserInChat, setIsUserInChat] = useState(false);

    let token = getCookie('token');
    const [socket, setSocket] = useState(
        io.connect(`${process.env.REACT_APP_SERVER_URL}`, {
            query: { token },
        })
    );

    useEffect(() => {
        if (isLoggedIn) {
            setTimeout(() => {
                let token = getCookie('token');
                setSocket(
                    io.connect(`${process.env.REACT_APP_SERVER_URL}`, {
                        query: { token },
                    })
                );
            }, 2000);
        }
    }, [isLoggedIn]);

    const changeLoginHandler = (trueOrFalse) => {
        setIsLoggedIn(trueOrFalse);
        setTimeout(() => {
            if (trueOrFalse) {
                let token = getCookie('token');
                setSocket(
                    io.connect(`${process.env.REACT_APP_SERVER_URL}`, {
                        query: { token },
                    })
                );
            }
        }, 2000);
    };

    const changeVornameHandler = (vorname) => {
        setProfileVorname(vorname);
    };

    const changeNachnameHandler = (nachname) => {
        setProfileNachname(nachname);
    };

    const changeFirmaHandler = (firma) => {
        setProfileFirma(firma);
    };

    const changeImageHandler = (imageUrl) => {
        setProfileImage(imageUrl);
    };

    const changeIsOnlineHandler = (trueOrFalse) => {
        setUserIsOnline(trueOrFalse);
    };

    const themeDarkBoolHandler = (trueOrFalse) => {
        setThemeDarkBool(trueOrFalse);
    };

    const howItWorksHandlerNoVideo = () => {
        setHowItWorks(!howItWorks);
    };

    const pricingCurrencyHandler = (currency) => {
        setPricingCurrency(currency);
        setCookie('currency', currency);
    };

    const setIsUserInChatHandler = (trueOrFalse) => {
        setIsUserInChat(trueOrFalse);
    };

    useEffect(() => {
        setPricingCurrency(getCookie('currency'));
    });

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: isLoggedIn,
                onChangeLogin: changeLoginHandler,

                profileVorname: profileVorname,
                onChangeVorname: changeVornameHandler,

                profileNachname: profileNachname,
                onChangeNachname: changeNachnameHandler,

                profileFirma: profileFirma,
                onChangeFirma: changeFirmaHandler,

                profileImage: profileImage,
                onChangeImage: changeImageHandler,

                socket: socket,
                userIsOnline: userIsOnline,
                onChangeIsOnline: changeIsOnlineHandler,

                themeDarkBool: themeDarkBool,
                onThemeIsDark: themeDarkBoolHandler,

                howItWorks: howItWorks,
                howItWorksHandlerNoVideo: howItWorksHandlerNoVideo,

                pricingCurrency: pricingCurrency,
                pricingCurrencyHandler: pricingCurrencyHandler,

                isUserInChat: isUserInChat,
                setIsUserInChatHandler: setIsUserInChatHandler,
            }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
