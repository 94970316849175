// @flow
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { isAuth, getCookie } from '../../context/helpers';
// constants
import * as layoutConstants from '../../constants/layout';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import AuthContext from '../../context/auth-context';

const LayoutMyConf = () => {
    const { t, i18n } = useTranslation();
    const ctx = useContext(AuthContext);

    const [isOrderMailReceive, setIsOrderMailReceive] = useState(false);
    const [isOrderMustNotHaveAnAccount, setIsOrderMustNotHaveAnAccount] = useState(false);
    const [googleIsAllowed, setGoogleIsAllowed] = useState(false);
    const [googleIsActive, setGoogleIsActive] = useState(false);

    const changeMailErhaltenByOrder = (e) => {
        setIsOrderMailReceive(e);
        if (!isAuth()) {
        } else {
            const token = getCookie('token');
            console.log('e', e);
            axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER_URL + '/auth/change-get-receive-mail-and-have-account',
                data: { isOrderMailReceive: e },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    console.log('resp1', response);
                })
                .catch((err) => {
                    console.log('error', err);
                });
        }
    };

    const changeGoogleIsActive = (e) => {
        setGoogleIsActive(e);
        if (!isAuth()) {
        } else {
            const token = getCookie('token');
            console.log('e', e);
            axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER_URL + '/auth/change-google-is-active',
                data: { googleIsActive: e },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    console.log('resp1', response);
                })
                .catch((err) => {
                    console.log('error', err);
                });
        }
    };

    const changeMustHaveAccountToOrder = (e) => {
        setIsOrderMustNotHaveAnAccount(e);
        if (!isAuth()) {
        } else {
            const token = getCookie('token');
            axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER_URL + '/auth/change-get-receive-mail-and-have-account',
                data: { isOrderMustNotHaveAnAccount: e },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    console.log('resp2', response);
                })
                .catch((err) => {
                    console.log('error', err);
                });
        }
    };

    useEffect(() => {
        if (!isAuth()) {
        } else {
            const token = getCookie('token');
            axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER_URL + '/auth/get-receive-mail-and-have-account',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.status == 200) {
                        setIsOrderMailReceive(response.data.data.isOrderMailReceive == '1' ? true : false);
                        setIsOrderMustNotHaveAnAccount(
                            response.data.data.isOrderMustNotHaveAnAccount == '1' ? true : false
                        );
                        setGoogleIsActive(response.data.data.activeOnGoogle == '1' ? true : false);
                    } else {
                        console.log('response', response);
                    }
                })
                .catch((err) => {
                    console.log('theee Error', err);
                });
        }
    }, [ctx.isLoggedIn]);

    useEffect(() => {
        if (!isAuth()) {
        } else {
            const token = getCookie('token');
            axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER_URL + '/auth/get-google-is-allowed',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.status == 200) {
                        setGoogleIsAllowed(true);
                    } else {
                        setGoogleIsAllowed(false);
                    }
                })
                .catch((err) => {
                    console.log('theee Error', err);
                });
        }
    }, [ctx.isLoggedIn]);

    /*  useEffect(() => {
        if (!isAuth()) {
        } else {
            const token = getCookie('token');

            axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER_URL + '/auth/change-get-receive-mail-and-have-account',
                data: { isOrderMailReceive, isOrderMustNotHaveAnAccount },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    console.log('response', response);
                })
                .catch((err) => {
                    console.log('error', err);
                });
        }
    }, [isOrderMailReceive, isOrderMustNotHaveAnAccount]); */

    return (
        <>
            <h5 className="mt-4">Konfiguration</h5>

            <hr className="mt-1" />

            {googleIsAllowed && (
                <Form.Check className="form-check form-switch mb-1">
                    <Form.Check.Input
                        type="checkbox"
                        onChange={(e) => changeGoogleIsActive(e.target.checked)}
                        name="layout-conf-google"
                        id="layout-conf-google-mode"
                        checked={googleIsActive}
                    />
                    <Form.Check.Label htmlFor="vertical-layout">Sichtbar in Google und SEO</Form.Check.Label>
                </Form.Check>
            )}

            <Form.Check className="form-check form-switch mb-1">
                <Form.Check.Input
                    type="checkbox"
                    onChange={(e) => changeMailErhaltenByOrder(e.target.checked)}
                    name="layout-conf-mail"
                    id="layout-conf-mail-mode"
                    checked={isOrderMailReceive}
                />
                <Form.Check.Label htmlFor="vertical-layout">Mail erhalten bei Bestellung</Form.Check.Label>
            </Form.Check>

            <Form.Check className="form-check form-switch mb-1">
                <Form.Check.Input
                    type="checkbox"
                    onChange={(e) => changeMustHaveAccountToOrder(e.target.checked)}
                    name="layout-conf-account"
                    id="layout-conf-account-mode"
                    checked={isOrderMustNotHaveAnAccount}
                />
                <Form.Check.Label htmlFor="horizontal-layout">
                    SuperMegaShop-Account für Bestellung <u>nicht</u> nötig
                </Form.Check.Label>
            </Form.Check>
        </>
    );
};

export default LayoutMyConf;
