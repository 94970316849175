import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { isAuth, getCookie } from './helpers';
import Select from 'react-select';
import { useTheme } from '@table-library/react-table-library/theme';
import { useSort } from '@table-library/react-table-library/sort';
import { CompactTable } from '@table-library/react-table-library/compact';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Row as BSRow, Col, Card } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import './style.css';

const AdminAssociatesIncome = () => {
    const [searchParams] = useSearchParams();
    const [productsActive, setProductsActive] = useState({ nodes: [] });
    const [countUsers, setCountUsers] = useState();
    const [search, setSearch] = useState('');
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [usersCsv, setUsersCsv] = useState();
    const [ids, setIds] = useState([]);

    const headers = [
        { label: 'Käufer ID', key: 'id' },
        { label: 'Stripe ID', key: 'stripeId' },
        { label: 'Abonnement', key: 'stripePlanPrice' },
        { label: 'Vermittler', key: 'vermittler_email' },
        { label: 'Sprache', key: 'language' },
        { label: 'Geschlecht', key: 'geschlecht' },
        { label: 'Name (Registrierung)', key: 'name' },
        { label: 'Vorname (Profil)', key: 'vorname' },
        { label: 'Nachname (Profil)', key: 'nachname' },
        { label: 'Adresse', key: 'adresse' },
        { label: 'PLZ', key: 'plz' },
        { label: 'Ort', key: 'ort' },
        { label: 'Bundesland / Kanton', key: 'kanton' },
        { label: 'Land', key: 'land' },
        { label: 'E-Mail', key: 'email' },
        { label: 'Festnetz', key: 'festnetz' },
        { label: 'Mobiltelefon', key: 'mobiltelefon' },
        { label: 'Bankverbindung', key: 'bankverbindung' },
        { label: 'Erstellt am', key: 'createdAt' },
        { label: 'Geändert am', key: 'updatedAt' },
    ];

    let theSize = searchParams.get('size');
    theSize = parseInt(theSize);

    let thePage = searchParams.get('page');
    thePage = parseInt(thePage);

    if (isNaN(theSize)) {
        theSize = 20;
    }

    if (isNaN(thePage)) {
        thePage = 0;
    }

    const toggleColumn = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter((v) => v !== column));
        } else {
            setHiddenColumns(hiddenColumns.concat(column));
        }
    };

    const handleExpand = (item) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const navigate = useNavigate();

    const [counterDollarNormal219, setcounterDollarNormal219] = useState(0);
    const [counterDollarNormal439, setcounterDollarNormal439] = useState(0);
    const [counterDollarNormal769, setcounterDollarNormal769] = useState(0);
    const [counterDollarNormal989, setcounterDollarNormal989] = useState(0);
    const [counterDollarNormal1429, setcounterDollarNormal1429] = useState(0);
    const [counterDollarNormal2199, setcounterDollarNormal2199] = useState(0);

    const [counterDollarTen199, setcounterDollarTen199] = useState(0);
    const [counterDollarTen389, setcounterDollarTen389] = useState(0);
    const [counterDollarTen689, setcounterDollarTen689] = useState(0);
    const [counterDollarTen889, setcounterDollarTen889] = useState(0);
    const [counterDollarTen1289, setcounterDollarTen1289] = useState(0);
    const [counterDollarTen11979, setcounterDollarTen1979] = useState(0);

    const [counterDollarTwentyFive159, setcounterDollarTwentyFive159] = useState(0);
    const [counterDollarTwentyFive329, setcounterDollarTwentyFive329] = useState(0);
    const [counterDollarTwentyFive579, setcounterDollarTwentyFive579] = useState(0);
    const [counterDollarTwentyFive739, setcounterDollarTwentyFive739] = useState(0);
    const [counterDollarTwentyFive1069, setcounterDollarTwentyFive1069] = useState(0);
    const [counterDollarTwentyFive1649, setcounterDollarTwentyFive1649] = useState(0);

    const [counterDollarThirtyFiveFive139, setcounterDollarThirtyFiveFive139] = useState(0);
    const [counterDollarThirtyFiveFive279, setcounterDollarThirtyFiveFive279] = useState(0);
    const [counterDollarThirtyFiveFive499, setcounterDollarThirtyFiveFive499] = useState(0);
    const [counterDollarThirtyFiveFive639, setcounterDollarThirtyFiveFive639] = useState(0);
    const [counterDollarThirtyFiveFive929, setcounterDollarThirtyFiveFive929] = useState(0);
    const [counterDollarThirtyFiveFive1499, setcounterDollarThirtyFiveFive1429] = useState(0);

    const [counterDollarFifty109, setcounterDollarFifty109] = useState(0);
    const [counterDollarFifty219, setcounterDollarFifty219] = useState(0);
    const [counterDollarFifty379, setcounterDollarFifty379] = useState(0);
    const [counterDollarFifty489, setcounterDollarFifty489] = useState(0);
    const [counterDollarFifty709, setcounterDollarFifty709] = useState(0);
    const [counterDollarFifty1099, setcounterDollarFifty1099] = useState(0);

    const [counterNormal199, setcounterNormal199] = useState(0);
    const [counterNormal399, setcounterNormal399] = useState(0);
    const [counterNormal699, setcounterNormal699] = useState(0);
    const [counterNormal899, setcounterNormal899] = useState(0);
    const [counterNormal1299, setcounterNormal1299] = useState(0);
    const [counterNormal1999, setcounterNormal1999] = useState(0);

    const [counterTen179, setcounterTen179] = useState(0);
    const [counterTen359, setcounterTen359] = useState(0);
    const [counterTen629, setcounterTen629] = useState(0);
    const [counterTen810, setcounterTen810] = useState(0);
    const [counterTen1170, setcounterTen1170] = useState(0);
    const [counterTen1799, setcounterTen1799] = useState(0);

    const [counterTwentyFive149, setcounterTwentyFive149] = useState(0);
    const [counterTwentyFive299, setcounterTwentyFive299] = useState(0);
    const [counterTwentyFive525, setcounterTwentyFive525] = useState(0);
    const [counterTwentyFive675, setcounterTwentyFive675] = useState(0);
    const [counterTwentyFive975, setcounterTwentyFive975] = useState(0);
    const [counterTwentyFive1499, setcounterTwentyFive1499] = useState(0);

    const [counterThirtyFiveFive129, setcounterThirtyFiveFive129] = useState(0);
    const [counterThirtyFiveFive259, setcounterThirtyFiveFive259] = useState(0);
    const [counterThirtyFiveFive455, setcounterThirtyFiveFive455] = useState(0);
    const [counterThirtyFiveFive585, setcounterThirtyFiveFive585] = useState(0);
    const [counterThirtyFiveFive845, setcounterThirtyFiveFive845] = useState(0);
    const [counterThirtyFiveFive1299, setcounterThirtyFiveFive1299] = useState(0);

    const [counterFifty99, setcounterFifty99] = useState(0);
    const [counterFifty199, setcounterFifty199] = useState(0);
    const [counterFifty349, setcounterFifty349] = useState(0);
    const [counterFifty449, setcounterFifty449] = useState(0);
    const [counterFifty649, setcounterFifty649] = useState(0);
    const [counterFifty999, setcounterFifty999] = useState(0);

    const [counterCHFNormal199, setcounterCHFNormal199] = useState(0);
    const [counterCHFNormal399, setcounterCHFNormal399] = useState(0);
    const [counterCHFNormal699, setcounterCHFNormal699] = useState(0);
    const [counterCHFNormal899, setcounterCHFNormal899] = useState(0);
    const [counterCHFNormal1299, setcounterCHFNormal1299] = useState(0);
    const [counterCHFNormal1999, setcounterCHFNormal1999] = useState(0);

    const [counterCHFTen179, setcounterCHFTen179] = useState(0);
    const [counterCHFTen359, setcounterCHFTen359] = useState(0);
    const [counterCHFTen629, setcounterCHFTen629] = useState(0);
    const [counterCHFTen810, setcounterCHFTen810] = useState(0);
    const [counterCHFTen1170, setcounterCHFTen1170] = useState(0);
    const [counterCHFTen1799, setcounterCHFTen1799] = useState(0);

    const [counterCHFTwentyFive149, setcounterCHFTwentyFive149] = useState(0);
    const [counterCHFTwentyFive299, setcounterCHFTwentyFive299] = useState(0);
    const [counterCHFTwentyFive525, setcounterCHFTwentyFive525] = useState(0);
    const [counterCHFTwentyFive675, setcounterCHFTwentyFive675] = useState(0);
    const [counterCHFTwentyFive975, setcounterCHFTwentyFive975] = useState(0);
    const [counterCHFTwentyFive1499, setcounterCHFTwentyFive1499] = useState(0);

    const [counterCHFThirtyFiveFive129, setcounterCHFThirtyFiveFive129] = useState(0);
    const [counterCHFThirtyFiveFive259, setcounterCHFThirtyFiveFive259] = useState(0);
    const [counterCHFThirtyFiveFive455, setcounterCHFThirtyFiveFive455] = useState(0);
    const [counterCHFThirtyFiveFive585, setcounterCHFThirtyFiveFive585] = useState(0);
    const [counterCHFThirtyFiveFive845, setcounterCHFThirtyFiveFive845] = useState(0);
    const [counterCHFThirtyFiveFive1299, setcounterCHFThirtyFiveFive1299] = useState(0);

    const [counterCHFFifty99, setcounterCHFFifty99] = useState(0);
    const [counterCHFFifty199, setcounterCHFFifty199] = useState(0);
    const [counterCHFFifty349, setcounterCHFFifty349] = useState(0);
    const [counterCHFFifty449, setcounterCHFFifty449] = useState(0);
    const [counterCHFFifty649, setcounterCHFFifty649] = useState(0);
    const [counterCHFFifty999, setcounterCHFFifty999] = useState(0);

    const [totalBrutto, setTotalBrutto] = useState(0);
    const [, setTotalNetto] = useState(0);
    const [totalDollarBrutto, setTotalDollarBrutto] = useState(0);
    const [, setTotalDollarNetto] = useState(0);
    const [totalBruttoCHF, setTotalBruttoCHF] = useState(0);
    const [totalNettoCHF, setTotalNettoCHF] = useState(0);
    const [totalDollarBruttoCHF, setTotalDollarBruttoCHF] = useState(0);
    const [totalDollarNettoCHF, setTotalDollarNettoCHF] = useState(0);
    const [epoche, setEpoche] = useState('');
    const [allPaymentsUnique, setAllPaymentsUnique] = useState([]);
    const [, setProzent] = useState(0);
    const [serverPercentage, setServerPercentage] = useState(0);

    const onChangeValues = (e, value) => {
        if (value === 'epoche') {
            setEpoche(e.label);
        }
    };

    useEffect(() => {
        if (!isAuth()) {
            navigate('/add-product');
        } else if (isAuth()) {
            const email = searchParams.get('email');
            const country = searchParams.get('country');
            const percentage = searchParams.get('percentage');
            setProzent(percentage);
            const token = getCookie('token');
            axios({
                method: 'post',
                url: process.env.REACT_APP_API + '/receive-income-of-associate',
                data: { email, country, percentage, epoche },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        const helperData = response.data.data;
                        console.log('response.data.foundPaymentsPercentage', response.data.foundPaymentsPercentage);
                        setServerPercentage(response.data.foundPaymentsPercentage);
                        setProductsActive({ nodes: helperData });
                        setCountUsers(response.data.countUsers);
                        setUsersCsv(helperData);
                        console.log('dude', response);

                        setTotalDollarBruttoCHF(0);
                        setTotalDollarBrutto(0);
                        setTotalBruttoCHF(0);
                        setTotalBrutto(0);
                        setTotalDollarNettoCHF(0);
                        setTotalDollarNetto(0);
                        setTotalNettoCHF(0);
                        setTotalNetto(0);

                        setcounterDollarNormal219(0);
                        setcounterDollarNormal439(0);
                        setcounterDollarNormal769(0);
                        setcounterDollarNormal989(0);
                        setcounterDollarNormal1429(0);
                        setcounterDollarNormal2199(0);

                        setcounterDollarTen199(0);
                        setcounterDollarTen389(0);
                        setcounterDollarTen689(0);
                        setcounterDollarTen889(0);
                        setcounterDollarTen1289(0);
                        setcounterDollarTen1979(0);

                        setcounterDollarTwentyFive159(0);
                        setcounterDollarTwentyFive329(0);
                        setcounterDollarTwentyFive579(0);
                        setcounterDollarTwentyFive739(0);
                        setcounterDollarTwentyFive1069(0);
                        setcounterDollarTwentyFive1649(0);

                        setcounterDollarThirtyFiveFive139(0);
                        setcounterDollarThirtyFiveFive279(0);
                        setcounterDollarThirtyFiveFive499(0);
                        setcounterDollarThirtyFiveFive639(0);
                        setcounterDollarThirtyFiveFive929(0);
                        setcounterDollarThirtyFiveFive1429(0);

                        setcounterDollarFifty109(0);
                        setcounterDollarFifty219(0);
                        setcounterDollarFifty379(0);
                        setcounterDollarFifty489(0);
                        setcounterDollarFifty709(0);
                        setcounterDollarFifty1099(0);

                        setcounterNormal199(0);
                        setcounterNormal399(0);
                        setcounterNormal699(0);
                        setcounterNormal899(0);
                        setcounterNormal1299(0);
                        setcounterNormal1999(0);

                        setcounterTen179(0);
                        setcounterTen359(0);
                        setcounterTen629(0);
                        setcounterTen810(0);
                        setcounterTen1170(0);
                        setcounterTen1799(0);

                        setcounterTwentyFive149(0);
                        setcounterTwentyFive299(0);
                        setcounterTwentyFive525(0);
                        setcounterTwentyFive675(0);
                        setcounterTwentyFive975(0);
                        setcounterTwentyFive1499(0);

                        setcounterThirtyFiveFive129(0);
                        setcounterThirtyFiveFive259(0);
                        setcounterThirtyFiveFive455(0);
                        setcounterThirtyFiveFive585(0);
                        setcounterThirtyFiveFive845(0);
                        setcounterThirtyFiveFive1299(0);

                        setcounterFifty99(0);
                        setcounterFifty199(0);
                        setcounterFifty349(0);
                        setcounterFifty449(0);
                        setcounterFifty649(0);
                        setcounterFifty999(0);

                        setcounterCHFNormal199(0);
                        setcounterCHFNormal399(0);
                        setcounterCHFNormal699(0);
                        setcounterCHFNormal899(0);
                        setcounterCHFNormal1299(0);
                        setcounterCHFNormal1999(0);

                        setcounterCHFTen179(0);
                        setcounterCHFTen359(0);
                        setcounterCHFTen629(0);
                        setcounterCHFTen810(0);
                        setcounterCHFTen1170(0);
                        setcounterCHFTen1799(0);

                        setcounterCHFTwentyFive149(0);
                        setcounterCHFTwentyFive299(0);
                        setcounterCHFTwentyFive525(0);
                        setcounterCHFTwentyFive675(0);
                        setcounterCHFTwentyFive975(0);
                        setcounterCHFTwentyFive1499(0);

                        setcounterCHFThirtyFiveFive129(0);
                        setcounterCHFThirtyFiveFive259(0);
                        setcounterCHFThirtyFiveFive455(0);
                        setcounterCHFThirtyFiveFive585(0);
                        setcounterCHFThirtyFiveFive845(0);
                        setcounterCHFThirtyFiveFive1299(0);

                        setcounterCHFFifty99(0);
                        setcounterCHFFifty199(0);
                        setcounterCHFFifty349(0);
                        setcounterCHFFifty449(0);
                        setcounterCHFFifty649(0);
                        setcounterCHFFifty999(0);

                        const allPaymentsUniqueDropdown = [];
                        response.data.allPaymentsUnique.forEach((element) => {
                            allPaymentsUniqueDropdown.push({
                                value: element,
                                label: element,
                            });
                        });

                        setAllPaymentsUnique(allPaymentsUniqueDropdown);

                        const allFinalPayments = response.data.foundPayments;

                        allFinalPayments.forEach((element) => {
                            if (element.paymentAmount == '1.99' && element.paymentCurrency == 'eur') {
                                setcounterNormal199((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.99' && element.paymentCurrency == 'eur') {
                                setcounterNormal399((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.99' && element.paymentCurrency == 'eur') {
                                setcounterNormal699((prev) => prev + 1);
                            } else if (element.paymentAmount == '8.99' && element.paymentCurrency == 'eur') {
                                setcounterNormal899((prev) => prev + 1);
                            } else if (element.paymentAmount == '12.99' && element.paymentCurrency == 'eur') {
                                setcounterNormal1299((prev) => prev + 1);
                            } else if (element.paymentAmount == '19.99' && element.paymentCurrency == 'eur') {
                                setcounterNormal1999((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.79' && element.paymentCurrency == 'eur') {
                                setcounterTen179((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.59' && element.paymentCurrency == 'eur') {
                                setcounterTen359((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.29' && element.paymentCurrency == 'eur') {
                                setcounterTen629((prev) => prev + 1);
                            } else if (element.paymentAmount == '8.10' && element.paymentCurrency == 'eur') {
                                setcounterTen810((prev) => prev + 1);
                            } else if (element.paymentAmount == '11.70' && element.paymentCurrency == 'eur') {
                                setcounterTen1170((prev) => prev + 1);
                            } else if (element.paymentAmount == '17.99' && element.paymentCurrency == 'eur') {
                                setcounterTen1799((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.49' && element.paymentCurrency == 'eur') {
                                setcounterTwentyFive149((prev) => prev + 1);
                            } else if (element.paymentAmount == '2.99' && element.paymentCurrency == 'eur') {
                                setcounterTwentyFive299((prev) => prev + 1);
                            } else if (element.paymentAmount == '5.25' && element.paymentCurrency == 'eur') {
                                setcounterTwentyFive525((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.75' && element.paymentCurrency == 'eur') {
                                setcounterTwentyFive675((prev) => prev + 1);
                            } else if (element.paymentAmount == '9.75' && element.paymentCurrency == 'eur') {
                                setcounterTwentyFive975((prev) => prev + 1);
                            } else if (element.paymentAmount == '14.99' && element.paymentCurrency == 'eur') {
                                setcounterTwentyFive1499((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.29' && element.paymentCurrency == 'eur') {
                                setcounterThirtyFiveFive129((prev) => prev + 1);
                            } else if (element.paymentAmount == '2.59' && element.paymentCurrency == 'eur') {
                                setcounterThirtyFiveFive259((prev) => prev + 1);
                            } else if (element.paymentAmount == '4.55' && element.paymentCurrency == 'eur') {
                                setcounterThirtyFiveFive455((prev) => prev + 1);
                            } else if (element.paymentAmount == '5.85' && element.paymentCurrency == 'eur') {
                                setcounterThirtyFiveFive585((prev) => prev + 1);
                            } else if (element.paymentAmount == '8.45' && element.paymentCurrency == 'eur') {
                                setcounterThirtyFiveFive845((prev) => prev + 1);
                            } else if (element.paymentAmount == ' 12.99' && element.paymentCurrency == 'eur') {
                                setcounterThirtyFiveFive1299((prev) => prev + 1);
                            } else if (element.paymentAmount == '0.99' && element.paymentCurrency == 'eur') {
                                setcounterFifty99((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.99' && element.paymentCurrency == 'eur') {
                                setcounterFifty199((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.49' && element.paymentCurrency == 'eur') {
                                setcounterFifty349((prev) => prev + 1);
                            } else if (element.paymentAmount == '4.49' && element.paymentCurrency == 'eur') {
                                setcounterFifty449((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.49' && element.paymentCurrency == 'eur') {
                                setcounterFifty649((prev) => prev + 1);
                            } else if (element.paymentAmount == '9.99' && element.paymentCurrency == 'eur') {
                                setcounterFifty999((prev) => prev + 1);
                            } else if (element.paymentAmount == '2.19' && element.paymentCurrency == 'usd') {
                                setcounterDollarNormal219((prev) => prev + 1);
                            } else if (element.paymentAmount == '4.39' && element.paymentCurrency == 'usd') {
                                setcounterDollarNormal439((prev) => prev + 1);
                            } else if (element.paymentAmount == '7.69' && element.paymentCurrency == 'usd') {
                                setcounterDollarNormal769((prev) => prev + 1);
                            } else if (element.paymentAmount == '9.89' && element.paymentCurrency == 'usd') {
                                setcounterDollarNormal989((prev) => prev + 1);
                            } else if (element.paymentAmount == '14.29' && element.paymentCurrency == 'usd') {
                                setcounterDollarNormal1429((prev) => prev + 1);
                            } else if (element.paymentAmount == '21.99' && element.paymentCurrency == 'usd') {
                                setcounterDollarNormal2199((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.99' && element.paymentCurrency == 'usd') {
                                setcounterDollarTen199((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.89' && element.paymentCurrency == 'usd') {
                                setcounterDollarTen389((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.89' && element.paymentCurrency == 'usd') {
                                setcounterDollarTen689((prev) => prev + 1);
                            } else if (element.paymentAmount == '8.89' && element.paymentCurrency == 'usd') {
                                setcounterDollarTen889((prev) => prev + 1);
                            } else if (element.paymentAmount == '12.89' && element.paymentCurrency == 'usd') {
                                setcounterDollarTen1289((prev) => prev + 1);
                            } else if (element.paymentAmount == '19.79' && element.paymentCurrency == 'usd') {
                                setcounterDollarTen1979((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.59' && element.paymentCurrency == 'usd') {
                                setcounterDollarTwentyFive159((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.29' && element.paymentCurrency == 'usd') {
                                setcounterDollarTwentyFive329((prev) => prev + 1);
                            } else if (element.paymentAmount == '5.79' && element.paymentCurrency == 'usd') {
                                setcounterDollarTwentyFive579((prev) => prev + 1);
                            } else if (element.paymentAmount == '7.39' && element.paymentCurrency == 'usd') {
                                setcounterDollarTwentyFive739((prev) => prev + 1);
                            } else if (element.paymentAmount == '10.69' && element.paymentCurrency == 'usd') {
                                setcounterDollarTwentyFive1069((prev) => prev + 1);
                            } else if (element.paymentAmount == '16.49' && element.paymentCurrency == 'usd') {
                                setcounterDollarTwentyFive1649((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.39' && element.paymentCurrency == 'usd') {
                                setcounterDollarThirtyFiveFive139((prev) => prev + 1);
                            } else if (element.paymentAmount == '2.79' && element.paymentCurrency == 'usd') {
                                setcounterDollarThirtyFiveFive279((prev) => prev + 1);
                            } else if (element.paymentAmount == '4.99' && element.paymentCurrency == 'usd') {
                                setcounterDollarThirtyFiveFive499((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.39' && element.paymentCurrency == 'usd') {
                                setcounterDollarThirtyFiveFive639((prev) => prev + 1);
                            } else if (element.paymentAmount == '9.29' && element.paymentCurrency == 'usd') {
                                setcounterDollarThirtyFiveFive929((prev) => prev + 1);
                            } else if (element.paymentAmount == '14.29' && element.paymentCurrency == 'usd') {
                                setcounterDollarThirtyFiveFive1429((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.09' && element.paymentCurrency == 'usd') {
                                setcounterDollarFifty109((prev) => prev + 1);
                            } else if (element.paymentAmount == '2.19' && element.paymentCurrency == 'usd') {
                                setcounterDollarFifty219((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.79' && element.paymentCurrency == 'usd') {
                                setcounterDollarFifty379((prev) => prev + 1);
                            } else if (element.paymentAmount == '4.89' && element.paymentCurrency == 'usd') {
                                setcounterDollarFifty489((prev) => prev + 1);
                            } else if (element.paymentAmount == '7.09' && element.paymentCurrency == 'usd') {
                                setcounterDollarFifty709((prev) => prev + 1);
                            } else if (element.paymentAmount == '10.99' && element.paymentCurrency == 'usd') {
                                setcounterDollarFifty1099((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFNormal199((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFNormal399((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFNormal699((prev) => prev + 1);
                            } else if (element.paymentAmount == '8.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFNormal899((prev) => prev + 1);
                            } else if (element.paymentAmount == '12.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFNormal1299((prev) => prev + 1);
                            } else if (element.paymentAmount == '19.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFNormal1999((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.79' && element.paymentCurrency == 'chf') {
                                setcounterCHFTen179((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.59' && element.paymentCurrency == 'chf') {
                                setcounterCHFTen359((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.29' && element.paymentCurrency == 'chf') {
                                setcounterCHFTen629((prev) => prev + 1);
                            } else if (element.paymentAmount == '8.10' && element.paymentCurrency == 'chf') {
                                setcounterCHFTen810((prev) => prev + 1);
                            } else if (element.paymentAmount == '11.70' && element.paymentCurrency == 'chf') {
                                setcounterCHFTen1170((prev) => prev + 1);
                            } else if (element.paymentAmount == '17.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFTen1799((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.49' && element.paymentCurrency == 'chf') {
                                setcounterCHFTwentyFive149((prev) => prev + 1);
                            } else if (element.paymentAmount == '2.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFTwentyFive299((prev) => prev + 1);
                            } else if (element.paymentAmount == '5.25' && element.paymentCurrency == 'chf') {
                                setcounterCHFTwentyFive525((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.75' && element.paymentCurrency == 'chf') {
                                setcounterCHFTwentyFive675((prev) => prev + 1);
                            } else if (element.paymentAmount == '9.75' && element.paymentCurrency == 'chf') {
                                setcounterCHFTwentyFive975((prev) => prev + 1);
                            } else if (element.paymentAmount == '14.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFTwentyFive1499((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.29' && element.paymentCurrency == 'chf') {
                                setcounterCHFThirtyFiveFive129((prev) => prev + 1);
                            } else if (element.paymentAmount == '2.59' && element.paymentCurrency == 'chf') {
                                setcounterCHFThirtyFiveFive259((prev) => prev + 1);
                            } else if (element.paymentAmount == '4.55' && element.paymentCurrency == 'chf') {
                                setcounterCHFThirtyFiveFive455((prev) => prev + 1);
                            } else if (element.paymentAmount == '5.85' && element.paymentCurrency == 'chf') {
                                setcounterCHFThirtyFiveFive585((prev) => prev + 1);
                            } else if (element.paymentAmount == '8.45' && element.paymentCurrency == 'chf') {
                                setcounterCHFThirtyFiveFive845((prev) => prev + 1);
                            } else if (element.paymentAmount == ' 12.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFThirtyFiveFive1299((prev) => prev + 1);
                            } else if (element.paymentAmount == '0.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFFifty99((prev) => prev + 1);
                            } else if (element.paymentAmount == '1.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFFifty199((prev) => prev + 1);
                            } else if (element.paymentAmount == '3.49' && element.paymentCurrency == 'chf') {
                                setcounterCHFFifty349((prev) => prev + 1);
                            } else if (element.paymentAmount == '4.49' && element.paymentCurrency == 'chf') {
                                setcounterCHFFifty449((prev) => prev + 1);
                            } else if (element.paymentAmount == '6.49' && element.paymentCurrency == 'chf') {
                                setcounterCHFFifty649((prev) => prev + 1);
                            } else if (element.paymentAmount == '9.99' && element.paymentCurrency == 'chf') {
                                setcounterCHFFifty999((prev) => prev + 1);
                            } else {
                                console.log('DEW', element.paymentAmount);
                            }
                        });

                        allFinalPayments.forEach((element) => {
                            if (element.paymentCurrency == 'eur') {
                                setTotalBruttoCHF((prev) => prev + parseFloat(element.paymentConverted_amount));
                                setTotalNettoCHF(
                                    (prev) =>
                                        prev +
                                        parseFloat(element.paymentConverted_amount) -
                                        parseFloat(element.paymentFee) -
                                        parseFloat(element.paymentConverted_amount_refunded)
                                );

                                setTotalBrutto((prev) => prev + parseFloat(element.paymentAmount));
                            } else if (element.paymentCurrency == 'usd') {
                                setTotalDollarBruttoCHF((prev) => prev + parseFloat(element.paymentConverted_amount));
                                setTotalDollarNettoCHF(
                                    (prev) =>
                                        prev +
                                        parseFloat(element.paymentConverted_amount) -
                                        parseFloat(element.paymentFee) -
                                        parseFloat(element.paymentConverted_amount_refunded)
                                );

                                setTotalDollarBrutto((prev) => prev + parseFloat(element.paymentAmount));
                            }
                        });
                    } else {
                        /* navigate('/add-product'); */
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                });
        } else {
            /* navigate('/add-product'); */
            console.log('wasa');
        }
    }, [epoche, navigate, searchParams]);

    const theme = useTheme({
        HeaderRow: `
          background-color: rgba(44, 144, 221, 0.3);
        `,
        Row: `
          &:nth-of-type(odd) {
            background-color: rgba(44, 144, 221, 0.1);
            cursor: pointer;
          }
    
          &:nth-of-type(even) {
            background-color: rgba(44, 144, 221, 0.2);
            cursor: pointer;
        }
        `,
    });

    const handleSearchTitel = (event) => {
        setSearch(event.target.value);
    };

    let dataSearch = productsActive;

    dataSearch = {
        nodes: dataSearch.nodes.filter(
            (item) =>
                (item.name + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.email + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.shopName + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.land + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.stripePlanPrice + '').toLowerCase().includes(search.toLowerCase())
        ),
    };

    const COLUMNS = [
        {
            label: 'Avatar Bild',
            renderCell: (item) => (
                <span>
                    {item.avatarImg ? (
                        <img width="50px" src={`${process.env.REACT_APP_SERVER_URL}/${item.avatarImg}`} alt="" />
                    ) : (
                        <i>nicht definiert</i>
                    )}
                </span>
            ),
            sort: { sortKey: 'AVATARIMG' },
            hide: hiddenColumns.includes('STRIPEDID'),
            resize: true,
        },

        {
            label: 'Name',
            renderCell: (item) => <span>{item.name ? item.name : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'NAME' },
            hide: hiddenColumns.includes('NAME'),
            resize: true,
        },
        {
            label: 'Shop',
            renderCell: (item) => <span>{item.shopName ? item.shopName : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'SHOPNAME' },
            hide: hiddenColumns.includes('SHOPNAME'),
            resize: true,
        },

        {
            label: 'Land',
            renderCell: (item) => <span>{item.land ? item.land : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'LAND' },
            hide: hiddenColumns.includes('LAND'),
            resize: true,
        },

        {
            label: 'Stripe Plan',
            renderCell: (item) => <span>{item.stripePlanPrice ? item.stripePlanPrice : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'AMOUNT' },
            hide: hiddenColumns.includes('AMOUNT'),
            resize: true,
        },
        {
            label: 'Erstelldatum',
            renderCell: (item) => <span>{item.createdAt ? item.createdAt : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'STARTDATUM' },
            hide: hiddenColumns.includes('STARTDATUM'),
            resize: true,
        },
    ];

    const sort = useSort(
        dataSearch,
        {
            onChange: onSortChange,
        },
        {
            sortFns: {
                ID: (array) => array.sort((a, b) => a.id - b.id),
                NAME: (array) => array.sort((a, b) => a.name - b.name),
                SHOPNAME: (array) => array.sort((a, b) => a.email - b.email),
                LAND: (array) => array.sort((a, b) => a.land - b.land),
                AMOUNT: (array) => array.sort((a, b) => a.stripePlanPrice - b.stripePlanPrice),
                STARTDATUM: (array) => array.sort((a, b) => a.createdAt - b.createdAt),
            },
        }
    );

    function onSortChange(action, state) {
        /* console.log(action, state); */
    }

    const ROW_PROPS = {
        onClick: handleExpand,
    };

    return (
        <>
            {/*  <PageTitle
                breadCrumbItems={[{ label: 'Was sind aktive Produkte?', path: '/apps/ecommerce/products' }]}
                title={'Aktive Produkte'}
            /> */}

            <BSRow className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <BSRow>
                                <Col sm={12}>
                                    <div style={{}}>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="NAME">
                                                <input
                                                    id="NAME"
                                                    type="checkbox"
                                                    value="NAME"
                                                    checked={!hiddenColumns.includes('NAME')}
                                                    onChange={() => toggleColumn('NAME')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Name
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="SHOPNAME">
                                                <input
                                                    id="SHOPNAME"
                                                    type="checkbox"
                                                    value="SHOPNAME"
                                                    checked={!hiddenColumns.includes('SHOPNAME')}
                                                    onChange={() => toggleColumn('SHOPNAME')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Shop
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="AMOUNT">
                                                <input
                                                    id="AMOUNT"
                                                    type="checkbox"
                                                    value="AMOUNT"
                                                    checked={!hiddenColumns.includes('AMOUNT')}
                                                    onChange={() => toggleColumn('AMOUNT')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Stripe Plan
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="LAND">
                                                <input
                                                    id="LAND"
                                                    type="checkbox"
                                                    value="LAND"
                                                    checked={!hiddenColumns.includes('LAND')}
                                                    onChange={() => toggleColumn('LAND')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Land
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="COUNTUSERS">
                                                Anzahl Users: <strong>{countUsers}</strong>
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            {usersCsv && (
                                                <CSVLink
                                                    data={usersCsv}
                                                    headers={headers}
                                                    filename={'SuperMegaShop.com-Käufer.csv'}>
                                                    <span>
                                                        <strong>CSV Export</strong>
                                                    </span>
                                                </CSVLink>
                                            )}
                                        </div>
                                    </div>
                                    <br />
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">
                                            <strong>Suche...</strong>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="ID, Name, E-Mail, Erstelldatum, Updatedatum, Stripe ID, Betrag, Firma, Vorname, Nachname, Adresse, PLZ, Ort, Festnetz, Mobiltelefon, Facebook, Instagram, Twitter, Skype "
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={search}
                                            onChange={handleSearchTitel}
                                        />
                                    </InputGroup>

                                    <div style={{ clear: 'both', marginTop: '20px', marginBottom: '0px' }}>
                                        <BSRow>
                                            <Col>
                                                <Select
                                                    className="react-select landUniIconContainerPublicProfile"
                                                    classNamePrefix="react-select"
                                                    onChange={(e) => {
                                                        onChangeValues(e, 'epoche');
                                                    }}
                                                    options={allPaymentsUnique}></Select>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Try Out</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Beginner</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Advanced</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Company</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Business</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Maximum</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    199: <strong>{counterNormal199}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    399: <strong>{counterNormal399}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    699: <strong>{counterNormal699}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    899: <strong>{counterNormal899}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1299: <strong>{counterNormal1299}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1999: <strong>{counterNormal1999}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    179: <strong>{counterTen179}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    359: <strong>{counterTen359}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    629: <strong>{counterTen629}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    810: <strong>{counterTen810}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1170: <strong>{counterTen1170}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1799: <strong>{counterTen1799}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    149: <strong>{counterTwentyFive149}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    299: <strong>{counterTwentyFive299}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    525: <strong>{counterTwentyFive525}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    675: <strong>{counterTwentyFive675}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    975: <strong>{counterTwentyFive975}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1499: <strong>{counterTwentyFive1499}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    129: <strong>{counterThirtyFiveFive129}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                {' '}
                                                <label className="me-2">
                                                    259: <strong>{counterThirtyFiveFive259}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    455: <strong>{counterThirtyFiveFive455}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    585: <strong>{counterThirtyFiveFive585}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    845: <strong>{counterThirtyFiveFive845}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1299: <strong>{counterThirtyFiveFive1299}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col>
                                                <label className="me-2">
                                                    99: <strong>{counterFifty99}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    199: <strong>{counterFifty199}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    349: <strong>{counterFifty349}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    449: <strong>{counterFifty449}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    649: <strong>{counterFifty649}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    999: <strong>{counterFifty999}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {totalBrutto.toFixed(2)} EUR
                                            </Col>

                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {totalBruttoCHF.toFixed(2)} CHF
                                            </Col>

                                            <Col lg={4}>
                                                Netto = (Brutto * 96.75% - 30 Cents pro Transaktion) *{' '}
                                                {serverPercentage}%
                                            </Col>
                                            <Col lg={2}></Col>
                                            <Col lg={2}>
                                                <strong>Netto:</strong>{' '}
                                                <span
                                                    style={{
                                                        textDecorationLine: 'underline',
                                                        textDecorationStyle: 'double',
                                                    }}>
                                                    {((totalNettoCHF * parseInt(serverPercentage)) / 100).toFixed(2)}{' '}
                                                    CHF
                                                </span>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                    </div>
                                    <div style={{ clear: 'both', marginTop: '20px', marginBottom: '0px' }}>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Try Out Dollar</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Beginner Dollar</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Advanced Dollar</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Company Dollar</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Business Dollar</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Maximum Dollar</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    219: <strong>{counterDollarNormal219}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    439: <strong>{counterDollarNormal439}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    769: <strong>{counterDollarNormal769}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    989: <strong>{counterDollarNormal989}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1429: <strong>{counterDollarNormal1429}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    2199: <strong>{counterDollarNormal2199}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    199: <strong>{counterDollarTen199}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    389: <strong>{counterDollarTen389}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    689: <strong>{counterDollarTen689}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    889: <strong>{counterDollarTen889}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1289: <strong>{counterDollarTen1289}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1979: <strong>{counterDollarTen11979}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    159: <strong>{counterDollarTwentyFive159}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    329: <strong>{counterDollarTwentyFive329}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    579: <strong>{counterDollarTwentyFive579}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    739: <strong>{counterDollarTwentyFive739}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1069: <strong>{counterDollarTwentyFive1069}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1649: <strong>{counterDollarTwentyFive1649}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    139: <strong>{counterDollarThirtyFiveFive139}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                {' '}
                                                <label className="me-2">
                                                    279: <strong>{counterDollarThirtyFiveFive279}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    499: <strong>{counterDollarThirtyFiveFive499}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    639: <strong>{counterDollarThirtyFiveFive639}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    929: <strong>{counterDollarThirtyFiveFive929}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1499: <strong>{counterDollarThirtyFiveFive1499}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col>
                                                <label className="me-2">
                                                    109: <strong>{counterDollarFifty109}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    219: <strong>{counterDollarFifty219}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    379: <strong>{counterDollarFifty379}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    489: <strong>{counterDollarFifty489}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    709: <strong>{counterDollarFifty709}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1099: <strong>{counterDollarFifty1099}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {totalDollarBrutto.toFixed(2)} USD
                                            </Col>
                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {totalDollarBruttoCHF.toFixed(2)} CHF
                                            </Col>
                                            <Col lg={4}>
                                                Netto = (Brutto * 96.75% - 30 Cents pro Transaktion) *{' '}
                                                {serverPercentage}%
                                            </Col>
                                            <Col lg={2}></Col>
                                            <Col lg={2}>
                                                <strong>Netto:</strong>{' '}
                                                <span
                                                    style={{
                                                        textDecorationLine: 'underline',
                                                        textDecorationStyle: 'double',
                                                    }}>
                                                    {((totalDollarNettoCHF * parseInt(serverPercentage)) / 100).toFixed(
                                                        2
                                                    )}{' '}
                                                    CHF
                                                </span>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                        <hr />
                                    </div>

                                    <div style={{ clear: 'both', marginTop: '20px', marginBottom: '0px' }}>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Try Out</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Beginner</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Advanced</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Company</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Business</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Maximum</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    199: <strong>{counterCHFNormal199}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    399: <strong>{counterCHFNormal399}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    699: <strong>{counterCHFNormal699}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    899: <strong>{counterCHFNormal899}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1299: <strong>{counterCHFNormal1299}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1999: <strong>{counterCHFNormal1999}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    179: <strong>{counterCHFTen179}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    359: <strong>{counterCHFTen359}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    629: <strong>{counterCHFTen629}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    810: <strong>{counterCHFTen810}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1170: <strong>{counterCHFTen1170}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1799: <strong>{counterCHFTen1799}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    149: <strong>{counterCHFTwentyFive149}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    299: <strong>{counterCHFTwentyFive299}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    525: <strong>{counterCHFTwentyFive525}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    675: <strong>{counterCHFTwentyFive675}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    975: <strong>{counterCHFTwentyFive975}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1499: <strong>{counterCHFTwentyFive1499}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    129: <strong>{counterCHFThirtyFiveFive129}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    259: <strong>{counterCHFThirtyFiveFive259}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    455: <strong>{counterCHFThirtyFiveFive455}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    585: <strong>{counterCHFThirtyFiveFive585}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    845: <strong>{counterCHFThirtyFiveFive845}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1299: <strong>{counterCHFThirtyFiveFive1299}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col>
                                                <label className="me-2">
                                                    99: <strong>{counterCHFFifty99}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    199: <strong>{counterCHFFifty199}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    349: <strong>{counterCHFFifty349}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    449: <strong>{counterCHFFifty449}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    649: <strong>{counterCHFFifty649}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    999: <strong>{counterCHFFifty999}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {totalBruttoCHF.toFixed(2)} CHF
                                            </Col>

                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {totalBruttoCHF.toFixed(2)} CHF
                                            </Col>

                                            <Col lg={4}>
                                                Netto = (Brutto * 96.75% - 30 Cents pro Transaktion) *{' '}
                                                {serverPercentage}%
                                            </Col>
                                            <Col lg={2}></Col>
                                            <Col lg={2}>
                                                <strong>Netto:</strong>{' '}
                                                <span
                                                    style={{
                                                        textDecorationLine: 'underline',
                                                        textDecorationStyle: 'double',
                                                    }}>
                                                    {((totalNettoCHF * parseInt(serverPercentage)) / 100).toFixed(2)}{' '}
                                                    CHF
                                                </span>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                    </div>
                                </Col>

                                <Col sm={3}>
                                    {/* <div className="text-sm-end">
                                        <Button variant="primary" className="mb-2 me-1">
                                            <i className="mdi mdi-cog-outline"></i>
                                        </Button>

                                        <Button variant="light" className="mb-2 me-1">
                                            Import
                                        </Button>

                                        <Button variant="light" className="mb-2">
                                            Export
                                        </Button>
                                    </div> */}
                                </Col>
                            </BSRow>

                            <CompactTable
                                columns={COLUMNS}
                                data={dataSearch}
                                theme={theme}
                                rowProps={ROW_PROPS}
                                sort={sort}
                                layout={{ fixedHeader: true }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </BSRow>
        </>
    );
};

export default AdminAssociatesIncome;
