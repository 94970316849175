// @flow
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { isAuth, getCookie } from './helpers';
import { useTheme } from '@table-library/react-table-library/theme';
import { useSort } from '@table-library/react-table-library/sort';
import { CompactTable } from '@table-library/react-table-library/compact';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from 'react-router-dom';
import { Row as BSRow, Col, Card } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import './style.css';

// main component
const AdminAssociates = () => {
    const [searchParams] = useSearchParams();
    const [productsActive, setProductsActive] = useState({ nodes: [] });
    const [, setCountUsers] = useState();
    const [search, setSearch] = useState('');
    const [hiddenColumns, setHiddenColumns] = React.useState([]);
    const [usersCsv, setUsersCsv] = useState();

    const headers = [
        { label: 'Käufer ID', key: 'id' },
        { label: 'Stripe ID', key: 'stripeId' },
        { label: 'Abonnement', key: 'stripePlanPrice' },
        { label: 'Vermittler', key: 'vermittler_email' },
        { label: 'Sprache', key: 'language' },
        { label: 'Geschlecht', key: 'geschlecht' },
        { label: 'Name (Registrierung)', key: 'name' },
        { label: 'Vorname (Profil)', key: 'vorname' },
        { label: 'Nachname (Profil)', key: 'nachname' },
        { label: 'Adresse', key: 'adresse' },
        { label: 'PLZ', key: 'plz' },
        { label: 'Ort', key: 'ort' },
        { label: 'Bundesland / Kanton', key: 'kanton' },
        { label: 'Land', key: 'land' },
        { label: 'E-Mail', key: 'email' },
        { label: 'Festnetz', key: 'festnetz' },
        { label: 'Mobiltelefon', key: 'mobiltelefon' },
        { label: 'Bankverbindung', key: 'bankverbindung' },
        { label: 'Erstellt am', key: 'createdAt' },
        { label: 'Geändert am', key: 'updatedAt' },
    ];

    let theSize = searchParams.get('size');
    theSize = parseInt(theSize);

    let thePage = searchParams.get('page');
    thePage = parseInt(thePage);

    if (isNaN(theSize)) {
        theSize = 20;
    }

    if (isNaN(thePage)) {
        thePage = 0;
    }

    const toggleColumn = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter((v) => v !== column));
        } else {
            setHiddenColumns(hiddenColumns.concat(column));
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth()) {
            navigate('/add-product');
        } else if (isAuth()) {
            const associateEmail = searchParams.get('email');
            const token = getCookie('token');
            axios({
                method: 'post',
                url: process.env.REACT_APP_API + '/receive-all-users-from-associate',
                data: { associateEmail },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        const helperData = response.data.data;
                        setProductsActive({ nodes: helperData });
                        setCountUsers(response.data.countUsers);
                        setUsersCsv(helperData);
                    } else {
                        navigate('/add-product');
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                });
        } else {
            navigate('/add-product');
        }
    }, [navigate, searchParams]);

    const theme = useTheme({
        HeaderRow: `
          background-color: rgba(44, 144, 221, 0.3);
        `,
        Row: `
          &:nth-of-type(odd) {
            background-color: rgba(44, 144, 221, 0.1);
            cursor: pointer;
          }
    
          &:nth-of-type(even) {
            background-color: rgba(44, 144, 221, 0.2);
            cursor: pointer;
        }
        `,
    });

    const handleSearchTitel = (event) => {
        setSearch(event.target.value);
    };

    let dataSearch = productsActive;

    dataSearch = {
        nodes: dataSearch.nodes.filter(
            (item) =>
                (item.land + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.email + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.percentage + '').toLowerCase().includes(search.toLowerCase())
        ),
    };

    const COLUMNS = [
        {
            label: 'E-Mail',
            renderCell: (item) => (
                <Link to={`/associate-income?email=${item.email}&country=${item.land}&percentage=${item.percentage}`}>
                    <span>{item.email ? item.email : <i>nicht definiert</i>}</span>
                </Link>
            ),
            sort: { sortKey: 'EMAIL' },
            hide: hiddenColumns.includes('EMAIL'),
            resize: true,
        },
        {
            label: 'Land',
            renderCell: (item) => (
                <Link to={`/associate-income?email=${item.email}&country=${item.land}&percentage=${item.percentage}`}>
                    <span>{item.land ? item.land : <i>nicht definiert</i>}</span>
                </Link>
            ),
            sort: { sortKey: 'LAND' },
            hide: hiddenColumns.includes('LAND'),
            resize: true,
        },
        {
            label: 'Prozent',
            renderCell: (item) => (
                <Link to={`/associate-income?email=${item.email}&country=${item.land}&percentage=${item.percentage}`}>
                    <span>{item.percentage ? item.percentage + '%' : <i>nicht definiert</i>}</span>
                </Link>
            ),
            sort: { sortKey: 'PERCENTAGE' },
            hide: hiddenColumns.includes('PERCENTAGE'),
            resize: true,
        },
    ];

    const sort = useSort(
        dataSearch,
        {
            onChange: onSortChange,
        },
        {
            sortFns: {
                EMAIL: (array) => array.sort((a, b) => a.email - b.email),
                LAND: (array) => array.sort((a, b) => a.vermittler - b.vermittler),
                PROZENT: (array) => array.sort((a, b) => a.stripeId - b.stripeId),
            },
        }
    );

    function onSortChange(action, state) {
        /* console.log(action, state); */
    }

    return (
        <>
            <BSRow className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <BSRow>
                                <Col sm={12}>
                                    <div style={{}}>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="EMAIL">
                                                <input
                                                    id="EMAIL"
                                                    type="checkbox"
                                                    value="EMAIL"
                                                    checked={!hiddenColumns.includes('EMAIL')}
                                                    onChange={() => toggleColumn('EMAIL')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                E-Mail
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="LAND">
                                                <input
                                                    id="LAND"
                                                    type="checkbox"
                                                    value="LAND"
                                                    checked={!hiddenColumns.includes('LAND')}
                                                    onChange={() => toggleColumn('LAND')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Land
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="PERCENTAGE">
                                                <input
                                                    id="PERCENTAGE"
                                                    type="checkbox"
                                                    value="PERCENTAGE"
                                                    checked={!hiddenColumns.includes('PERCENTAGE')}
                                                    onChange={() => toggleColumn('PERCENTAGE')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Prozent
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            {usersCsv && (
                                                <CSVLink
                                                    data={usersCsv}
                                                    headers={headers}
                                                    filename={'SuperMegaShop.com-Käufer.csv'}>
                                                    <span>
                                                        <strong>CSV Export</strong>
                                                    </span>
                                                </CSVLink>
                                            )}
                                        </div>
                                    </div>
                                    <br />
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">
                                            <strong>Suche...</strong>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="ID, Name, E-Mail, Erstelldatum, Updatedatum, Stripe ID, Betrag, Firma, Vorname, Nachname, Adresse, PLZ, Ort, Festnetz, Mobiltelefon, Facebook, Instagram, Twitter, Skype "
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={search}
                                            onChange={handleSearchTitel}
                                        />
                                    </InputGroup>
                                    {/*    <div style={{ clear: 'both', marginTop: '20px', marginBottom: '0px' }}>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Try Out</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Beginner</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Advanced</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Company</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Business</u>
                                                    </strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    <strong>
                                                        <u>Maximum</u>
                                                    </strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    199: <strong>{counterNormal199}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    399: <strong>{counterNormal399}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    699: <strong>{counterNormal699}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    899: <strong>{counterNormal899}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1299: <strong>{counterNormal1299}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1999: <strong>{counterNormal1999}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    179: <strong>{counterTen179}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    359: <strong>{counterTen359}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    629: <strong>{counterTen629}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    810: <strong>{counterTen810}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1170: <strong>{counterTen1170}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1799: <strong>{counterTen1799}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    149: <strong>{counterTwentyFive149}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    299: <strong>{counterTwentyFive299}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    525: <strong>{counterTwentyFive525}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    675: <strong>{counterTwentyFive675}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    975: <strong>{counterTwentyFive975}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1499: <strong>{counterTwentyFive1499}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    129: <strong>{counterThirtyFiveFive129}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                {' '}
                                                <label className="me-2">
                                                    259: <strong>{counterThirtyFiveFive259}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    455: <strong>{counterThirtyFiveFive455}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    585: <strong>{counterThirtyFiveFive585}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    845: <strong>{counterThirtyFiveFive845}</strong>
                                                </label>{' '}
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    1299: <strong>{counterThirtyFiveFive1299}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <BSRow>
                                            <Col>
                                                <label className="me-2">
                                                    99: <strong>{counterFifty99}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    199: <strong>{counterFifty199}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    349: <strong>{counterFifty349}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    449: <strong>{counterFifty449}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    649: <strong>{counterFifty649}</strong>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label className="me-2">
                                                    999: <strong>{counterFifty999}</strong>
                                                </label>
                                            </Col>
                                        </BSRow>
                                        <hr />
                                        <BSRow>
                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {totalBrutto.toFixed(2)} Cents
                                            </Col>
                                            <Col lg={2}>
                                                <strong>Brutto:</strong> {(totalBrutto / 100).toFixed(2)} EUR
                                            </Col>
                                            <Col lg={4}>Netto = (Brutto * 96.75% - 30 Cents pro Transaktion) * 50%</Col>
                                            <Col lg={2}>
                                                <strong>Netto:</strong> {(totalNetto * 0.5).toFixed(2)} Cents
                                            </Col>
                                            <Col lg={2}>
                                                <strong>Netto:</strong> {((totalNetto / 100) * 0.5).toFixed(2)} EUR
                                            </Col>
                                        </BSRow>
                                        <hr />
                                        <hr />
                                    </div> */}
                                </Col>

                                <Col sm={3}>
                                    {/* <div className="text-sm-end">
                                        <Button variant="primary" className="mb-2 me-1">
                                            <i className="mdi mdi-cog-outline"></i>
                                        </Button>

                                        <Button variant="light" className="mb-2 me-1">
                                            Import
                                        </Button>

                                        <Button variant="light" className="mb-2">
                                            Export
                                        </Button>
                                    </div> */}
                                </Col>
                            </BSRow>
                            <br />
                            <CompactTable
                                columns={COLUMNS}
                                data={dataSearch}
                                theme={theme}
                                sort={sort}
                                layout={{ fixedHeader: true }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </BSRow>
        </>
    );
};

export default AdminAssociates;
