import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationFR from './locales/fr/translation.json';
import translationIT from './locales/it/translation.json';
import translationES from './locales/es/translation.json';
import translationRU from './locales/ru/translation.json';

//translations
const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
    fr: {
        translation: translationFR,
    },
    it: {
        translation: translationIT,
    },
    es: {
        translation: translationES,
    },
    ru: {
        translation: translationRU,
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en', // use en if detected lng is not available
        debug: true,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
