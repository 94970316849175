import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { AuthContextProvider } from './context/auth-context';
import { Suspense } from 'react';

ReactDOM.render(
    <AuthContextProvider>
        <Provider store={configureStore({})}>
            <Suspense fallback="...loading">
                <App />
            </Suspense>
        </Provider>
    </AuthContextProvider>,
    document.getElementById('root')
);
