// @flow
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { isAuth, getCookie, getLocalStorage } from './helpers';
import { useTheme } from '@table-library/react-table-library/theme';
import { useSort } from '@table-library/react-table-library/sort';
import { CompactTable } from '@table-library/react-table-library/compact';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from 'react-router-dom';
import { Row as BSRow, Col, Card } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import './style.css';

// main component
const AdminUserChats = () => {
    const [searchParams] = useSearchParams();

    const [productsActive, setProductsActive] = useState({ nodes: [] });
    const [countUsers] = useState();
    const [search, setSearch] = useState('');
    const [hiddenColumns, setHiddenColumns] = React.useState([]);
    const [usersCsv, setUsersCsv] = useState();
    const [ids, setIds] = React.useState([]);

    const headers = [
        { label: 'Käufer ID', key: 'id' },
        { label: 'Stripe ID', key: 'stripeId' },
        { label: 'Abonnement', key: 'stripePlanPrice' },
        { label: 'Vermittler', key: 'vermittler_email' },
        { label: 'Sprache', key: 'language' },
        { label: 'Geschlecht', key: 'geschlecht' },
        { label: 'Name (Registrierung)', key: 'name' },
        { label: 'Vorname (Profil)', key: 'vorname' },
        { label: 'Nachname (Profil)', key: 'nachname' },
        { label: 'Adresse', key: 'adresse' },
        { label: 'PLZ', key: 'plz' },
        { label: 'Ort', key: 'ort' },
        { label: 'Bundesland / Kanton', key: 'kanton' },
        { label: 'Land', key: 'land' },
        { label: 'E-Mail', key: 'email' },
        { label: 'Festnetz', key: 'festnetz' },
        { label: 'Mobiltelefon', key: 'mobiltelefon' },
        { label: 'Bankverbindung', key: 'bankverbindung' },
        { label: 'Erstellt am', key: 'createdAt' },
        { label: 'Geändert am', key: 'updatedAt' },
    ];

    let theSize = searchParams.get('size');
    theSize = parseInt(theSize);

    let thePage = searchParams.get('page');
    thePage = parseInt(thePage);

    if (isNaN(theSize)) {
        theSize = 20;
    }

    if (isNaN(thePage)) {
        thePage = 0;
    }

    const toggleColumn = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter((v) => v !== column));
        } else {
            setHiddenColumns(hiddenColumns.concat(column));
        }
    };

    const handleExpand = (item) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        const token = getCookie('token');
        let requestUser = searchParams.get('userId');
        let targetUser = searchParams.get('targetUser');
        const adminUserLocalStorage = getLocalStorage('user');

        if (!isAuth()) {
            navigate('/add-product');
        } else if (isAuth() && JSON.parse(adminUserLocalStorage).email == 'schmid.justin@gmail.com') {
            axios({
                method: 'post',
                url: process.env.REACT_APP_API + '/rulebreak-chats-admin',
                data: { requestUser, targetUser },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    console.log('response', response);
                    if (response.status === 200) {
                        const helperData = response.data.data.rows;
                        setProductsActive({ nodes: helperData });

                        setUsersCsv(helperData);
                        console.log('hello', response);
                    } else {
                        /* navigate('/add-product'); */
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                });
        } else {
            navigate('/add-product');
        }
    }, [navigate, searchParams]);

    const theme = useTheme({
        HeaderRow: `
          background-color: rgba(44, 144, 221, 0.3);
        `,
        Row: `
          &:nth-of-type(odd) {
            background-color: rgba(44, 144, 221, 0.1);
            cursor: pointer;
          }
    
          &:nth-of-type(even) {
            background-color: rgba(44, 144, 221, 0.2);
            cursor: pointer;
        }
        `,
    });

    const handleSearchTitel = (event) => {
        setSearch(event.target.value);
    };

    let dataSearch = productsActive;

    dataSearch = {
        nodes: dataSearch.nodes.filter(
            (item) =>
                (item.id + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.name + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.email + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.vermittler_email + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.createdAt + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.updatedAt + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.stripeId + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.stripePlanPrice + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.language + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.vorname + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.nachname + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.adresse + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.plz + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.ort + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.festnetz + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.mobiltelefon + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.facebook + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.instagram + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.twitter + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.skype + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.bankverbindung + '').toLowerCase().includes(search.toLowerCase()) ||
                (item.deactivated + '').toLowerCase().includes(search.toLowerCase())
        ),
    };

    const COLUMNS = [
        {
            label: 'Sender ID',
            renderCell: (item) => (
                <Link to={`/penti?search-user=${item.senderId}`}>
                    {item.user.email ? <span>{item.user.email}</span> : <i>nicht definiert</i>}
                </Link>
            ),
            sort: { sortKey: 'SENDERID' },
            hide: hiddenColumns.includes('SENDERID'),
            resize: true,
        },

        {
            label: 'Message',
            renderCell: (item) => <span>{item.message ? item.message : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'MESSAGE' },
            hide: hiddenColumns.includes('MESSAGE'),
            resize: true,
        },

        {
            label: 'Receiver ID',
            renderCell: (item) => (
                <Link to={`/penti?search-user=${item.receiverId}`}>
                    {item.receiverId ? <span>{item.receiverId}</span> : <i>nicht definiert</i>}
                </Link>
            ),
            sort: { sortKey: 'RECEIVERID' },
            hide: hiddenColumns.includes('RECEIVERID'),
            resize: true,
        },

        {
            label: 'Sent Date/Time',
            renderCell: (item) => <span>{item.createdAt ? item.createdAt : <i>nicht definiert</i>}</span>,
            sort: { sortKey: 'CREATEDAT' },
            hide: hiddenColumns.includes('CREATEDAT'),
            resize: true,
        },
    ];

    const sort = useSort(
        dataSearch,
        {
            onChange: onSortChange,
        },
        {
            sortFns: {
                ID: (array) => array.sort((a, b) => a.id - b.id),
                SENDERID: (array) => array.sort((a, b) => a.senderId - b.senderId),
                RECEIVERID: (array) => array.sort((a, b) => a.receiverId - b.receiverId),
                CREATEDAT: (array) => array.sort((a, b) => a.createdAt - b.createdAt),
                MESSAGE: (array) => array.sort((a, b) => a.message - b.message),
            },
        }
    );

    function onSortChange(action, state) {
        /* console.log(action, state); */
    }

    const ROW_PROPS = {
        onClick: handleExpand,
    };

    return (
        <>
            {/*  <PageTitle
                breadCrumbItems={[{ label: 'Was sind aktive Produkte?', path: '/apps/ecommerce/products' }]}
                title={'Aktive Produkte'}
            /> */}

            <BSRow className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <BSRow>
                                <Col sm={12} className="mb-3">
                                    <div style={{}}>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="ID">
                                                <input
                                                    id="ID"
                                                    type="checkbox"
                                                    value="ID"
                                                    checked={!hiddenColumns.includes('ID')}
                                                    onChange={() => toggleColumn('ID')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                ID
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="USEREMAIL">
                                                <input
                                                    id="USEREMAIL"
                                                    type="checkbox"
                                                    value="USEREMAIL"
                                                    checked={!hiddenColumns.includes('USEREMAIL')}
                                                    onChange={() => toggleColumn('USEREMAIL')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                User E-Mail
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="EMAIL">
                                                <input
                                                    id="EMAIL"
                                                    type="checkbox"
                                                    value="EMAIL"
                                                    checked={!hiddenColumns.includes('EMAIL')}
                                                    onChange={() => toggleColumn('EMAIL')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                E-Mail
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="STARTDATUM">
                                                <input
                                                    id="STARTDATUM"
                                                    type="checkbox"
                                                    value="STARTDATUM"
                                                    checked={!hiddenColumns.includes('STARTDATUM')}
                                                    onChange={() => toggleColumn('STARTDATUM')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Erstelldatum
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="VERMITTLER">
                                                <input
                                                    id="VERMITTLER"
                                                    type="checkbox"
                                                    value="VERMITTLER"
                                                    checked={!hiddenColumns.includes('VERMITTLER')}
                                                    onChange={() => toggleColumn('VERMITTLER')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Vermittler
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="STRIPEID">
                                                <input
                                                    id="STRIPEID"
                                                    type="checkbox"
                                                    value="STRIPEID"
                                                    checked={!hiddenColumns.includes('STRIPEID')}
                                                    onChange={() => toggleColumn('STRIPEID')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Stripe ID
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="AMOUNT">
                                                <input
                                                    id="AMOUNT"
                                                    type="checkbox"
                                                    value="AMOUNT"
                                                    checked={!hiddenColumns.includes('AMOUNT')}
                                                    onChange={() => toggleColumn('AMOUNT')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                Betrag
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="COUNTUSERS">
                                                Anzahl Users: <strong>{countUsers}</strong>
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            {usersCsv && (
                                                <CSVLink
                                                    data={usersCsv}
                                                    headers={headers}
                                                    filename={'SuperMegaShop.com-Käufer.csv'}>
                                                    <span>
                                                        <strong>CSV Export</strong>
                                                    </span>
                                                </CSVLink>
                                            )}
                                        </div>
                                    </div>
                                    <br />
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">
                                            <strong>Suche...</strong>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="ID, Name, E-Mail, Erstelldatum, Updatedatum, Stripe ID, Betrag, Firma, Vorname, Nachname, Adresse, PLZ, Ort, Festnetz, Mobiltelefon, Facebook, Instagram, Twitter, Skype "
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={search}
                                            onChange={handleSearchTitel}
                                        />
                                    </InputGroup>
                                </Col>

                                <Col sm={3}>
                                    {/* <div className="text-sm-end">
                                        <Button variant="primary" className="mb-2 me-1">
                                            <i className="mdi mdi-cog-outline"></i>
                                        </Button>

                                        <Button variant="light" className="mb-2 me-1">
                                            Import
                                        </Button>

                                        <Button variant="light" className="mb-2">
                                            Export
                                        </Button>
                                    </div> */}
                                </Col>
                            </BSRow>

                            <CompactTable
                                columns={COLUMNS}
                                data={dataSearch}
                                theme={theme}
                                rowProps={ROW_PROPS}
                                sort={sort}
                                layout={{ fixedHeader: true }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </BSRow>
        </>
    );
};

export default AdminUserChats;
