// @flow
import React, { useEffect, useState, useContext } from 'react';

import axios from 'axios';
import { isAuth, getCookie } from './helpers';

import { useTheme } from '@table-library/react-table-library/theme';
import { useSort } from '@table-library/react-table-library/sort';
import { CompactTable } from '@table-library/react-table-library/compact';

import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { CSVLink } from 'react-csv';

import { Link } from 'react-router-dom';
import { Row as BSRow, Col, Card, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './style.css';
import AuthContext from '../../../../context/auth-context';

// main component
const ProductsActiveGoogle = () => {
    const { t } = useTranslation();
    const ctx = useContext(AuthContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [, setTheStatusTemporaer] = useState(false);
    const [, setTheStatusAktiv] = useState(false);
    const [, setTheStatusPassiv] = useState(false);
    const [, setTheStatusVerkauft] = useState(false);
    const [, setTheStatusGeliefert] = useState(false);
    const [, setTheStatusArchiv] = useState(false);
    const [, setTheStatusGeloescht] = useState(false);
    const [totalSize, setTotalSize] = useState();
    const [productsCsv, setProductsCsv] = useState();
    const [productsActive, setProductsActive] = useState({ nodes: [] });
    const [search, setSearch] = useState('');
    const [hiddenColumns, setHiddenColumns] = React.useState([]);

    const [ids, setIds] = React.useState([]);

    const theStatus = 'Aktiv';

    const headers = [
        { label: 'title', key: 'titel' },
        { label: 'status', key: 'status' },
        { label: 'subtitle', key: 'untertitel' },
        { label: 'category', key: 'kategorie' },
        { label: 'subcategory', key: 'unterkategorie' },
        { label: 'subsubcategory', key: 'unterunterkategorie' },

        { label: 'productUserId', key: 'produktid' },
        { label: 'warehouse', key: 'lager' },
        { label: 'numberOfPiesces', key: 'anzahlstueck' },

        { label: 'auctionSalesPlatform', key: 'verkaufsplattform' },
        { label: 'auctionSalesNumber', key: 'artikelnummer' },
        { label: 'auctionSalesWeblink', key: 'auktionsweblink' },
        { label: 'auctionSalesStartdate', key: 'startdatum' },
        { label: 'auctionSalesEnddate', key: 'enddatum' },

        { label: 'originalPrice', key: 'startpreis' },
        { label: 'discountedPrice', key: 'fixpreis' },
        { label: 'purchasingPrice', key: 'einkaufspreis' },

        { label: 'paymentType', key: 'bezahlung' },
        { label: 'deliveryCosts', key: 'lieferkosten' },
        { label: 'paymentTerm', key: 'zahlungsfrist' },
        { label: 'deliveryType', key: 'lieferungstyp' },

        { label: 'condition', key: 'zustand' },
        { label: 'guarantee', key: 'garantie' },
        { label: 'brand', key: 'marke' },

        { label: 'length', key: 'laenge' },
        { label: 'width', key: 'breite' },
        { label: 'height', key: 'hoehe' },

        { label: 'material', key: 'material' },
        { label: 'color', key: 'farbe' },
        { label: 'size', key: 'groesse' },
        { label: 'weight', key: 'gewicht' },
        { label: 'description', key: 'beschreibung' },
        { label: 'privateNotes', key: 'notizen' },

        { label: 'imageCount', key: 'bilderCount' },
        { label: 'createdAt', key: 'createdAt' },
        { label: 'updatedAt', key: 'updatedAt' },
        { label: 'smsId', key: 'id' },
    ];

    const changeSize = (argSize) => {
        setSearchParams({ status: theStatus, size: argSize, page: '0' });
    };

    let theSize = searchParams.get('size');
    theSize = parseInt(theSize);

    let thePage = searchParams.get('page');
    thePage = parseInt(thePage);

    if (isNaN(theSize)) {
        theSize = 50;
    }

    if (isNaN(thePage)) {
        thePage = 0;
    }

    let countItems = [];

    for (let number = 0; number < totalSize / theSize; number++) {
        countItems.push(
            <Link key={number} className="m-1" to={`/products-active?size=${theSize}&page=${number}`}>
                <ToggleButton active={number === thePage}>{number + 1}</ToggleButton>
            </Link>
        );
    }

    const toggleColumn = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter((v) => v !== column));
        } else {
            setHiddenColumns(hiddenColumns.concat(column));
        }
    };

    const handleExpand = (item) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    useEffect(() => {
        if (!isAuth()) {
        } else {
            const token = getCookie('token');
            axios({
                method: 'put',
                url: process.env.REACT_APP_API + '/put-site-location',
                data: { location: 'Aktiv' },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {})
                .catch((err) => {});
        }
    }, []);

    const [updateAfterCategoryImage, setUpdateAfterCategoryImage] = useState(0);

    useEffect(() => {
        if (!isAuth()) {
        } else {
            if (theStatus === 'Temporär') {
                setTheStatusTemporaer(true);
            } else if (theStatus === 'Aktiv') {
                setTheStatusAktiv(true);
            } else if (theStatus === 'Passiv') {
                setTheStatusPassiv(true);
            } else if (theStatus === 'Verkauft') {
                setTheStatusVerkauft(true);
            } else if (theStatus === 'Geliefert') {
                setTheStatusGeliefert(true);
            } else if (theStatus === 'Archiv') {
                setTheStatusArchiv(true);
            } else if (theStatus === 'Gelöscht') {
                setTheStatusGeloescht(true);
            } else {
                setTheStatusTemporaer(true);
            }
            const token = getCookie('token');
            axios({
                method: 'post',
                url: process.env.REACT_APP_API + '/receive-active-products-pc',
                data: { status: 'Aktiv', size: theSize, page: thePage },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    const helperData = response.data.data;
                    setProductsActive({
                        nodes: helperData.rows,
                    });
                    setProductsCsv(helperData.rows);
                    setTotalSize(helperData.count);
                })
                .catch((err) => {
                    console.log('err', err);
                });
        }
    }, [theStatus, searchParams, theSize, thePage, updateAfterCategoryImage]);

    const theme = useTheme({
        HeaderRow: `
          background-color: rgba(44, 144, 221, 0.3);
        `,
        Row: `
          &:nth-of-type(odd) {
            background-color: rgba(44, 144, 221, 0.1);
            cursor: pointer;
          }
    
          &:nth-of-type(even) {
            background-color: rgba(44, 144, 221, 0.2);
            cursor: pointer;
        }
        `,
    });

    const handleSearchTitel = (event) => {
        setSearch(event.target.value);
        if (searchParams.get('size') != 2500) {
            changeSize(2500);
        }
    };

    let dataSearch = productsActive;

    dataSearch = {
        nodes: dataSearch.nodes.filter(
            (item) =>
                (item.kategorie && item.kategorie.toLowerCase().includes(search.toLowerCase())) ||
                (item.titel && item.titel.toLowerCase().includes(search.toLowerCase())) ||
                (item.unterkategorie && item.unterkategorie.toLowerCase().includes(search.toLowerCase())) ||
                (item.unterunterkategorie && item.unterunterkategorie.toLowerCase().includes(search.toLowerCase())) ||
                (item.startdatumhuman && item.startdatumhuman.includes(search.toLowerCase())) ||
                (item.enddatumhuman && item.enddatumhuman.includes(search.toLowerCase())) ||
                (item.untertitel && item.untertitel.toLowerCase().includes(search.toLowerCase())) ||
                (item.produktid && item.produktid.toString().includes(search.toLowerCase())) ||
                (item.artikelnummer && item.artikelnummer.toLowerCase().includes(search.toLowerCase())) ||
                (item.id && item.id.toString().includes(search.toLowerCase())) ||
                (item.verkaufsplattform && item.verkaufsplattform.toLowerCase().includes(search.toLowerCase())) ||
                (item.lager && item.lager.toLowerCase().includes(search.toLowerCase())) ||
                (item.zustand && item.zustand.toLowerCase().includes(search.toLowerCase())) ||
                (item.garantie && item.garantie.toLowerCase().includes(search.toLowerCase())) ||
                (item.beschreibung && item.beschreibung.toLowerCase().includes(search.toLowerCase())) ||
                (item.bezahlung && item.bezahlung.toLowerCase().includes(search.toLowerCase())) ||
                (item.lieferungstyp && item.lieferungstyp.toLowerCase().includes(search.toLowerCase())) ||
                (item.marke && item.marke.toLowerCase().includes(search.toLowerCase())) ||
                (item.oeffentlich && item.oeffentlich.toLowerCase().includes(search.toLowerCase())) ||
                (item.notizen && item.notizen.toLowerCase().includes(search.toLowerCase()))
        ),
    };

    const handleUpdate = (value, titel, prodID, seitenstatus, theIndex) => {
        let theTranslatedValue = 0;
        if (theIndex === 0) {
            theTranslatedValue = `${t('Button.Temporaer')}`;
        } else if (theIndex === 1) {
            theTranslatedValue = `${t('Button.Aktiv')}`;
        } else if (theIndex === 2) {
            theTranslatedValue = `${t('Button.Passiv')}`;
        } else if (theIndex === 3) {
            theTranslatedValue = `${t('Button.Verkauft')}`;
        } else if (theIndex === 4) {
            theTranslatedValue = `${t('Button.Geliefert')}`;
        } else if (theIndex === 5) {
            theTranslatedValue = `${t('Button.Archiv')}`;
        } else if (theIndex === 6) {
            theTranslatedValue = `${t('Button.Geloescht')}`;
        } else {
            theTranslatedValue = `${t('Button.Temporaer')}`;
        }

        const dataOfProductForm = {
            prodID,
            value,
            titel,
            seitenstatus,
            theSize,
            thePage,
            theTranslatedValue,
        };

        const dataOfProductFormJSON = JSON.stringify(dataOfProductForm);
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/change-status-products',
            data: dataOfProductFormJSON,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    setProductsActive({ nodes: result.data.data });
                    toast.success(
                        `${t('JSONtoast.StatusVon')} ${titel} ${t('JSONtoast.WurdeGeaendertZu')} ${theTranslatedValue}`,
                        {
                            toastId: 'custom-id-yes',
                            position: 'top-center',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                            transition: Zoom,
                        }
                    );
                } else if (result.status === 201) {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {});
    };

    const frontPageActiveHandler = (e, itemId) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/change-product-to-frontpage',
            data: { frontPageProductStatus: e.target.checked, itemId: itemId },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`${t('JSONtoast.ProduktZuFrontpage')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 201) {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {});
    };

    const categoryImageActiveHandler = (e, itemId, hauptkategorie) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/change-to-category-image',
            data: {
                categoryImageProductStatus: e.target.checked,
                itemId: itemId,
                hauptkategorie: hauptkategorie,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    setUpdateAfterCategoryImage((prev) => prev + 1);
                    toast.success(`${t('JSONtoast.ImageZuKategorie')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 203) {
                    toast.error(`${t('JSONtoast.KeineKategorieInProdukt')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 201) {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {});
    };

    const topProductOneActiveHandler = (e, itemId) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/change-to-top-product-one',
            data: {
                topProductOneActiveStatus: e.target.checked,
                itemId: itemId,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    setUpdateAfterCategoryImage((prev) => prev + 1);
                    toast.success(`${t('JSONtoast.ImageZuKategorie')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 203) {
                    toast.error(`${t('JSONtoast.KeineKategorieInProdukt')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 201) {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {});
    };

    const topProductTwoActiveHandler = (e, itemId) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/change-to-top-product-two',
            data: {
                topProductTwoActiveStatus: e.target.checked,
                itemId: itemId,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    setUpdateAfterCategoryImage((prev) => prev + 1);
                    toast.success(`${t('JSONtoast.ImageZuKategorie')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 203) {
                    toast.error(`${t('JSONtoast.KeineKategorieInProdukt')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 201) {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {});
    };

    const topProductThreeActiveHandler = (e, itemId) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/change-to-top-product-three',
            data: {
                topProductThreeActiveStatus: e.target.checked,
                itemId: itemId,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    setUpdateAfterCategoryImage((prev) => prev + 1);
                    toast.success(`${t('JSONtoast.ImageZuKategorie')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 203) {
                    toast.error(`${t('JSONtoast.KeineKategorieInProdukt')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 201) {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {});
    };

    const productOnGoogleActiveHandler = (e, itemId) => {
        const token = getCookie('token');
        axios({
            method: 'post',
            url: process.env.REACT_APP_API + '/show-product-on-google',
            data: {
                topProductOnGoogleStatus: e.target.checked,
                itemId: itemId,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                if (result.status === 200) {
                    setUpdateAfterCategoryImage((prev) => prev + 1);
                    toast.success(`${t('JSONtoast.ImageZuKategorie')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 203) {
                    toast.error(`${t('JSONtoast.KeineKategorieInProdukt')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else if (result.status === 201) {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        toastId: 'custom-id-yes',
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                } else {
                    console.log('Error', result.data.data);
                    toast.error(`${t('JSONtoast.ServerError')}`, {
                        position: 'top-center',
                        autoClose: 7000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                        transition: Zoom,
                    });
                }
            })
            .catch((err) => {});
    };

    const COLUMNS = [
        {
            label: `${t('Button.Hauptbild')}`,
            renderCell: (item) => (
                <Link to={`/add-product?title=${item.titel}`}>
                    {item.mainImage && searchParams.get('size') < 101 ? (
                        <img
                            className="previewImgHover"
                            height="60px"
                            src={
                                process.env.REACT_APP_SERVER_URL +
                                '/' +
                                item.mainImage +
                                '?' +
                                new Date().getMilliseconds()
                            }
                            alt={item.titel}
                        />
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60px"
                            fill="#BBB"
                            className="bi bi-images"
                            viewBox="0 0 16 16">
                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                            <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                        </svg>
                    )}
                </Link>
            ),
            hide: hiddenColumns.includes('BILD'),
            resize: true,
        },

        {
            label: `${t('Button.Titel')}`,
            renderCell: (item) => (
                <Link to={`/add-product?title=${item.titel}`}>
                    <span>{item.titel ? item.titel : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}</span>
                </Link>
            ),
            sort: { sortKey: 'TITEL' },
            hide: hiddenColumns.includes('TITEL'),
            resize: true,
        },

        {
            label: `${t('Button.Kategorie')}`,
            renderCell: (item) => (
                <span>{item.kategorie ? item.kategorie : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}</span>
            ),
            sort: { sortKey: 'KATEGORIE' },
            hide: hiddenColumns.includes('KATEGORIE'),
            resize: true,
        },

        {
            label: `${t('Button.KategorieBild')}`,
            renderCell: (item) => (
                <Form.Check
                    className="categoryimage"
                    type="checkbox"
                    checked={item.categoryImage == '1' ? true : false}
                    id={'categoryImageCheckBox'}
                    onChange={(e, itemId) => categoryImageActiveHandler(e, item.id, item.kategorie)}
                />
            ),
            sort: { sortKey: 'CATEGORYIMAGE' },
            hide: hiddenColumns.includes('CATEGORYIMAGE'),
            resize: true,
        },

        {
            label: `${t('Button.TopProductOne')}`,
            renderCell: (item) => (
                <Form.Check
                    className="topProductOneCheckBox"
                    type="checkbox"
                    checked={item.topProductOne == '1' ? true : false}
                    id={'topProductOneCheckBox'}
                    onChange={(e) => topProductOneActiveHandler(e, item.id)}
                />
            ),
            sort: { sortKey: 'TOPPRODUCTONE' },
            hide: hiddenColumns.includes('TOPPRODUCTONE'),
            resize: true,
        },

        {
            label: `${t('Button.TopProductTwo')}`,
            renderCell: (item) => (
                <Form.Check
                    className="TopProductTwo"
                    type="checkbox"
                    checked={item.topProductTwo == '1' ? true : false}
                    id={'topProductTwoCheckBox'}
                    onChange={(e) => topProductTwoActiveHandler(e, item.id)}
                />
            ),
            sort: { sortKey: 'TOPPRODUCTTWO' },
            hide: hiddenColumns.includes('TOPPRODUCTTWO'),
            resize: true,
        },

        {
            label: `${t('Button.TopProductThree')}`,
            renderCell: (item) => (
                <Form.Check
                    className="TopProductThree"
                    type="checkbox"
                    checked={item.topProductThree == '1' ? true : false}
                    id={'TopProductThreeCheckBox'}
                    onChange={(e) => topProductThreeActiveHandler(e, item.id)}
                />
            ),
            sort: { sortKey: 'TOPPRODUCTTHREE' },
            hide: hiddenColumns.includes('TOPPRODUCTTHREE'),
            resize: true,
        },

        {
            label: `${t('Button.ShownOnGoogle')}`,
            renderCell: (item) => (
                <Form.Check
                    className="ShownOnGoogle"
                    type="checkbox"
                    checked={item.productOnGoogle == '1' ? true : false}
                    id={'ShownOnGoogle'}
                    onChange={(e) => productOnGoogleActiveHandler(e, item.id)}
                />
            ),
            sort: { sortKey: 'SHOWNONGOOGLE' },
            hide: hiddenColumns.includes('SHOWNONGOOGLE'),
            resize: true,
        },
    ];

    const sort = useSort(
        productsActive.nodes,
        {
            onChange: onSortChange,
        },
        {
            sortFns: {
                TITEL: (array) => array.sort((a, b) => (a.titel > b.titel ? 1 : b.titel > a.titel ? -1 : 0)),
                KATEGORIE: (array) =>
                    array.sort((a, b) => (a.kategorie > b.kategorie ? 1 : b.kategorie > a.kategorie ? -1 : 0)),
                UNTERKATEGORIE: (array) =>
                    array.sort((a, b) =>
                        a.unterkategorie > b.unterkategorie ? 1 : b.unterkategorie > a.unterkategorie ? -1 : 0
                    ),
                CATEGORYIMAGE: (array) => array.sort((a, b) => a.categoryImage - b.categoryImage),
                TOPPRODUCTONE: (array) => array.sort((a, b) => a.topProductOne - b.topProductOne),
                TOPPRODUCTTWO: (array) => array.sort((a, b) => a.topProductTwo - b.topProductTwo),
                TOPPRODUCTTHREE: (array) => array.sort((a, b) => a.topProductThree - b.topProductThree),
                SHOWNONGOOGLE: (array) => array.sort((a, b) => a.productOnGoogle - b.productOnGoogle),
            },
        }
    );

    function onSortChange(action, state) {
        if (searchParams.get('size') != 2500) {
            changeSize(2500);
        }
    }

    const ROW_PROPS = {
        onClick: handleExpand,
    };

    const ROW_OPTIONS = {
        renderAfterRow: (item) => (
            <>
                {ids.includes(item.id) && (
                    <BSRow
                        style={{
                            display: 'flex',
                            gridColumn: '1 / -1',
                            backgroundColor: 'rgba(44, 144, 221, 0.3)',
                            margin: '0px',
                        }}>
                        <Col md="2" style={{ padding: '20px' }}>
                            <strong>{t('NeuesProdukt.InterneID')}: </strong>
                            <span className="showTableZF">
                                {item.id ? item.id : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                            <br />
                            <strong>{t('NeuesProdukt.ProduktID')}: </strong>
                            <span className="showTableZF">
                                {item.produktid ? item.produktid : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                        </Col>

                        <Col md="2" style={{ paddingTop: '20px', paddingLeft: '14px' }}>
                            <strong>{t('NeuesProdukt.Unterkategorie')}: </strong>
                            <span className="showTableZF">
                                {item.unterkategorie ? item.unterkategorie : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                            <br />
                            <strong>{t('NeuesProdukt.Subunterkategorie')}: </strong>
                            <span className="showTableZF">
                                {item.unterunterkategorie ? (
                                    item.unterunterkategorie
                                ) : (
                                    <i>{t('NeuesProdukt.NichtDefiniert')}</i>
                                )}
                            </span>
                            <br />
                        </Col>
                        <Col md="2" style={{ paddingTop: '20px', paddingLeft: '34px' }}>
                            <strong>{t('NeuesProdukt.Preis')}: </strong>
                            <span className="showTableZF">
                                {item.fixpreis ? item.fixpreis : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                            <br />
                            <strong>{t('NeuesProdukt.Einkaufspreis')}: </strong>
                            <span className="showTableZF">
                                {item.einkaufspreis ? item.einkaufspreis : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                        </Col>
                        <Col md="2" style={{ paddingTop: '20px', paddingLeft: '34px' }}>
                            <strong>{t('NeuesProdukt.Lieferkosten')}: </strong>
                            <span className="showTableZF">
                                {item.lieferkosten ? item.lieferkosten : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>

                            <br />
                            <strong>{t('NeuesProdukt.Bezahlung')}: </strong>
                            <span className="showTableZF">
                                {item.bezahlung ? item.bezahlung : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                        </Col>
                        <Col md="2" style={{ paddingTop: '20px', paddingLeft: '34px' }}>
                            <strong>{t('NeuesProdukt.Lager')}: </strong>
                            <span className="showTableZF">
                                {item.lager ? item.lager : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                            <br />
                            <strong>{t('NeuesProdukt.AnzahlStueck')}: </strong>
                            <span className="showTableZF">
                                {item.anzahlstueck ? item.anzahlstueck : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                        </Col>
                        {/* <Col md="2" style={{ paddingTop: '20px', paddingLeft: '34px' }}>
                           <strong>Zustand: </strong>
                           <span className="showTableZF">{item.zustand ? item.zustand : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}</span>
                           <br />
                           <strong>Garantie: </strong>
                           <span className="showTableZF">
                               {item.garantie ? item.garantie : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                           </span>
                           <br />
                           <strong>Marke: </strong>
                           <span className="showTableZF">{item.marke ? item.marke : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}</span>
                       </Col> */}
                        <Col md="2" style={{ paddingTop: '20px', paddingLeft: '34px' }}>
                            <strong>{t('NeuesProdukt.Notizen')}: </strong>
                            <span className="showTableZF">
                                {item.notizen ? item.notizen : <i>{t('NeuesProdukt.NichtDefiniert')}</i>}
                            </span>
                            <br />
                            <strong>{t('NeuesProdukt.Oeffentlich')}: </strong>
                            <span className="showTableZF">
                                {item && item.status === 'Aktiv'
                                    ? `${t('NeuesProdukt.Ja')}`
                                    : `${t('NeuesProdukt.Nein')}`}
                            </span>
                        </Col>
                    </BSRow>
                )}
            </>
        ),
    };

    return (
        <>
            {/*  <PageTitle
                breadCrumbItems={[{ label: 'Was sind aktive Produkte?', path: '/apps/ecommerce/products' }]}
                title={'Aktive Produkte'}
            /> */}

            <BSRow className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <BSRow>
                                <Col sm={12}>
                                    <Link to="/add-product">
                                        <Button style={{ float: 'left' }}>
                                            📦 {t('Button.NeuesProduktErstellen')}
                                        </Button>
                                    </Link>
                                    <div style={{}}>
                                        <div style={{ marginLeft: '15px', float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_bild">
                                                <input
                                                    id="titel_bild"
                                                    type="checkbox"
                                                    value="TITEL"
                                                    checked={!hiddenColumns.includes('BILD')}
                                                    onChange={() => toggleColumn('BILD')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('Button.Hauptbild')}
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_checkbox">
                                                <input
                                                    id="titel_checkbox"
                                                    type="checkbox"
                                                    value="TITEL"
                                                    checked={!hiddenColumns.includes('TITEL')}
                                                    onChange={() => toggleColumn('TITEL')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('NeuesProdukt.Titel')}
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_kategorie">
                                                <input
                                                    id="titel_kategorie"
                                                    type="checkbox"
                                                    value="KATEGORIE"
                                                    checked={!hiddenColumns.includes('KATEGORIE')}
                                                    onChange={() => toggleColumn('KATEGORIE')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('Button.Kategorie')}
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_unterkategorie">
                                                <input
                                                    id="titel_unterkategorie"
                                                    type="checkbox"
                                                    value="UNTERKATEGORIE"
                                                    checked={!hiddenColumns.includes('UNTERKATEGORIE')}
                                                    onChange={() => toggleColumn('UNTERKATEGORIE')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('Button.Unterkategorie')}
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_startdatum">
                                                <input
                                                    id="titel_startdatum"
                                                    type="checkbox"
                                                    value="SUBUNTERKATEGORIE"
                                                    checked={!hiddenColumns.includes('SUBUNTERKATEGORIE')}
                                                    onChange={() => toggleColumn('SUBUNTERKATEGORIE')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('NeuesProdukt.Subunterkategorie')}
                                            </label>
                                        </div>

                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_status">
                                                <input
                                                    id="titel_status"
                                                    type="checkbox"
                                                    value="STATUS"
                                                    checked={!hiddenColumns.includes('STATUS')}
                                                    onChange={() => toggleColumn('STATUS')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('Button.Status')}
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_KategorieBild">
                                                <input
                                                    id="titel_KategorieBild"
                                                    type="checkbox"
                                                    value="CATEGORYIMAGE"
                                                    checked={!hiddenColumns.includes('CATEGORYIMAGE')}
                                                    onChange={() => toggleColumn('CATEGORYIMAGE')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('Button.KategorieBild')}
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            <label htmlFor="titel_frontpage">
                                                <input
                                                    id="titel_frontpage"
                                                    type="checkbox"
                                                    value="FRONTPAGE"
                                                    checked={!hiddenColumns.includes('FRONTPAGE')}
                                                    onChange={() => toggleColumn('FRONTPAGE')}
                                                    style={{
                                                        marginRight: '5px',
                                                        width: '20px',
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                {t('Button.FrontSeite')}
                                            </label>
                                        </div>
                                        <div style={{ float: 'left', padding: '10px' }}>
                                            {productsCsv && (
                                                <CSVLink
                                                    separator={';SmS;'}
                                                    data={productsCsv}
                                                    headers={headers}
                                                    filename={`SuperMegaShop-${new Date().toLocaleDateString(
                                                        `${t('Language.LanguageCode')}`,
                                                        {
                                                            year: 'numeric',
                                                            month: 'numeric',
                                                            day: 'numeric',
                                                        }
                                                    )}-${t('Button.Aktiv')}.csv`}>
                                                    <span>
                                                        <strong>{t('Kaeufer.CSV')}</strong>
                                                    </span>
                                                </CSVLink>
                                            )}
                                        </div>
                                    </div>

                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">
                                            <strong>
                                                <strong>{`${t('NeuesProduktInputField.Suche')}`}</strong>
                                            </strong>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder={`${t('NeuesProduktInputField.SuchePlaceholder')}`}
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={search}
                                            onChange={handleSearchTitel}
                                        />
                                    </InputGroup>
                                </Col>

                                <Col sm={3}>
                                    {/* <div className="text-sm-end">
                                        <Button variant="primary" className="mb-2 me-1">
                                            <i className="mdi mdi-cog-outline"></i>
                                        </Button>

                                        <Button variant="light" className="mb-2 me-1">
                                            Import
                                        </Button>

                                        <Button variant="light" className="mb-2">
                                            Export
                                        </Button>
                                    </div> */}
                                </Col>
                            </BSRow>
                            <BSRow>
                                {ctx.howItWorks && (
                                    <Col>
                                        <div className="speechProductsTableActive mt-2">
                                            <div style={{ color: 'white' }}>
                                                Hier sind die aktiven Produkte aufgelistet. Aktiv bedeutet online in
                                                Ihrem Webshop sichtbar und bereit für den Erwerb von potentiellen
                                                Käufern. Mit der Aktivierung des Auswahlkästchens Frontseite, ist das
                                                Produkt unter Top Produkte in Ihrem Shop dargestellt.
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </BSRow>
                            <br />
                            <CompactTable
                                columns={COLUMNS}
                                data={dataSearch}
                                theme={theme}
                                sort={sort}
                                rowProps={ROW_PROPS}
                                rowOptions={ROW_OPTIONS}
                                layout={{ fixedHeader: true }}
                            />

                            <ToggleButtonGroup name="options" type="radio">
                                <BSRow>
                                    <Col>{countItems}</Col>
                                </BSRow>
                            </ToggleButtonGroup>

                            <select
                                onChange={(event) => changeSize(event.target.value)}
                                style={{
                                    border: '2px solid #2C90DD4D',
                                    height: '33px',
                                    fontSize: '1.2rem',
                                    padding: '0',
                                    margin: '0',
                                    width: '75px',
                                    float: 'right',
                                }}>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="2500">2500</option>
                            </select>
                        </Card.Body>
                    </Card>
                </Col>
            </BSRow>
        </>
    );
};

export default ProductsActiveGoogle;
