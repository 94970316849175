import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const Routes = () => {
    const { t } = useTranslation();
    return (
        <BrowserRouter>
            <AllRoutes />
            <CookieConsent
                buttonText={`${t('Language.CookieAccept')}`}
                cookieName="AcceptCookies"
                style={{
                    background: 'linear-gradient(0deg, #291c62 0%, #002c7a 100%)',
                    textShadow: '2px 2px black',
                    zIndex: '99999',
                    textAlign: 'center',
                    width: '50%',
                    display: 'inline-block',
                    margin: '0 auto',
                    left: '25%',
                    borderRadius: '15px',
                    marginBottom: '15px',
                    paddingBottom: '15px',
                    border: '1px solid rgba(255,255,255,0.5)',
                    boxShadow: '0px 0px 3px white',
                    fontSize: '16px',
                }}
                buttonStyle={{
                    background: 'rgba(255,255,255,0.2)',
                    color: 'white',
                    fontWeight: 'bolder',
                    textShadow: '2px 2px 3px black',
                    border: '1px solid #000000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto',
                    borderRadius: '5px',
                }}>
                {t('Language.Cookie')}
            </CookieConsent>
        </BrowserRouter>
    );
};

export default Routes;
